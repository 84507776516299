import React, { useEffect } from "react";
import { fobeAxios } from "../middleware/interceptor";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Table, Space, Input, InputNumber } from "antd";
import {
  faAngleLeft,
  faAngleRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InsertPageBreakOutlinedIcon from "@mui/icons-material/InsertPageBreakOutlined";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { ScanOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import "./invoice.css";
import { Modal, Button } from "react-bootstrap";
import { format } from "date-fns";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  faTimes,
  faCheck,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { getNodeText } from "@testing-library/react";
import * as moment from 'moment';
const MappingTopBar = ({
  setpDisabled,
  setnDisabled,

  tdata,
  tadata,
  setLoadingaimap,
  changingStatus,
  getData,
  check,
  fonlydisabled,
  flter,
  setTotal,
  total,
  setflag,
  flag,
  setNoterecord,
  setNotes,
  setNotesPopup,
  showDeleteIcon,
  record,
  invoiceData,
  Multideleteitem,
  reFreshFUnction,
  Deletepopup,
  nextAndPrevious,
  handleDropdown5Change,
  dropdown5Value,
  nDisabled,
  pDisabled,
  qa_review,
  setQa_review,
  setFile_status,
  file_status,
  dfortotal,
}) => {

  const navigate = useNavigate();
  const [invSource, setInvSource] = useState();
  const [invoiceDate, setInvoiceDate] = useState("");
  const [records, setRecord] = useState("");
  const [PurveyorRawName, setPurveyorRawName] = useState("");

  const [showmultidelete, setShowmultidelete] = useState(false);
  const [InvoiceNumber, setinvoiceNumber] = useState("");
  const items = [
    { key: "5", label: "Processing", disabled: false },
    { key: "2", label: "Completed", disabled: true },
    { key: "3", label: "Re-scan", disabled: false },
    { key: "4", label: "Flagged", disabled: false },
    { key: "7", label: "Duplicate", disabled: true },
  ];
  const [InvoiceDatas, setInvoiceDatas] = useState("");
  useEffect(() => {
    if (records.index == 0) {
      setpDisabled(true);
    } else {
      setpDisabled(false);
    }
    if (tadata.length - 1 == records.index) {
      setnDisabled(true);
    } else {
      setnDisabled(false);
    }
  }, [tadata]);

  useEffect(() => {

    setRecord(record);
    changingStatus(record.ReviewData);
    setInvSource(record?.ReviewData?.invoice_from);
    if (
      record?.ReviewData?.invoice_from == "INVOICE_UPLOAD") {
      setInvSource("Invoice Upload");
    } else if (record?.ReviewData?.invoice_from == "From_Mail") {
      setInvSource("Invoice Email");
    } else if (record?.ReviewData?.invoice_from == "SPLIT_PDF") {
      setInvSource("Split Invoice");
    }else if (record?.ReviewData?.invoice_from == "MERGE_PDF") {
      setInvSource("Merge Invoice");
    } else if (record?.ReviewData?.invoice_from == "SYSCO EDI") {
      setInvSource("Sysco EDI");
    } else if (record?.ReviewData?.invoice_from == "PFG EDI") {
      setInvSource("PFG EDI");
    } else if (record?.ReviewData?.invoice_from == "MCLANE EDI") {
      setInvSource("McLane EDI");
    }
    else if (record?.ReviewData?.invoice_from == "Benekeith EDI") {
      setInvSource("Ben E. Keith EDI");

    } else if(record?.ReviewData?.invoice_from == "USFOOD"){
      setInvSource("US Foods EDI");
    }
    else {
      setInvSource("");
    }
    setFile_status(record?.ReviewData?.file_status);
    if (invoiceData != undefined && invoiceData != "") {
      setInvoiceDatas(invoiceData.data);
      setPurveyorRawName(invoiceData.data.PurveyorRawName);
      var temp_date = invoiceData.data.invoice_date == '' ? new Date().toISOString().slice(0, 10) : moment(invoiceData.data.invoice_date.slice(0, 10)).format('YYYY-MM-DD');
      temp_date = temp_date == 'Invalid date' ? moment(invoiceData.data.invoice_date.slice(0, 8)).format('YYYY-MM-DD') : temp_date;
      let check;
      if(record?.ReviewData?.Budget_type == 2){
        check = (new Date(invoiceData.data.Budget_year+"-01-01") < new Date(temp_date) && new Date(new Date().getFullYear()+"-12-31") > new Date(temp_date))
      }
      if(record?.ReviewData?.Budget_type == 1 || record?.ReviewData?.Budget_type == 3){
        check = (new Date(invoiceData.data.Budget_year) < new Date(temp_date) && new Date(new Date().getFullYear()+"-12-31") > new Date(temp_date))
      }
      //  = record?.ReviewData?.Budget_type == 1 ? true : 
      setInvoiceDate(check ? temp_date : '');
      setinvoiceNumber(invoiceData.data.invoice_number);
    } else {
      setInvoiceDatas("");
      setinvoiceNumber("");
      setPurveyorRawName("");
      setInvoiceDate("");
    }

    // ArrangeDetails();
  }, [record, invoiceData]);



  useEffect(() => {


    if (dfortotal != "" && dfortotal != null && dfortotal != undefined) {

      const sumOfIds = dfortotal.reduce((acc, obj) => {
        return acc + parseFloat(obj.Total ? obj.Total : 0);
      }, 0);
      setTotal(sumOfIds);
    } else {
      setTotal(0);
    }
  }, [dfortotal]);







  useEffect(() => {
    if (tdata.length > 0) {
      let index = tdata.findIndex(
        (x) => x.Upload_id === record.ReviewData.Upload_id
      );
      fonlydisabled(index);
    }
  }, []);

  // const formatDateforInvoiceDate = (dateStr) => {
  //   const date = new Date(dateStr);

  //   const year = date.getFullYear();

  //   const month = String(date.getMonth() + 1).padStart(2, "0");

  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${year}-${month}-${day}`;
  // };

  const split_pdf = (e) => {
    e.preventDefault();
    navigate("/split", { state: records.ReviewData });
  };

  function viewinvoicee(e) {
    e.preventDefault();
    window.open(records.ReviewData.filename);
  }
  const getNotes = async (record) => {
    var record = record.ReviewData;
    setNoterecord(record);
    await fobeAxios(
      "api/Daily/getNotes/" + record.Rest_id + "/" + record.Upload_id
    )
      .then((d) => {
        setNotesPopup(true);
        setNotes(d.data.LatestNotes);
        // getData();
        check();
        handleDropdown5Change(dropdown5Value);
      })
      .catch(() => {
        console.log("Went Wrong");
      });
  };
  const handlechangeStatus = async (e) => {
    setFile_status(e.target.value);
    record.ReviewData.file_status = e.target.value;
    setRecord(record);
    let d = {
      Rest_id: records.ReviewData.Rest_id,
      upload_id: records.ReviewData.Upload_id,
      filestatus: e.target.value,
    };

    await fobeAxios
      .post("api/Daily/save_invoice_status", d)
      .then(() => {
        toast.success("Status Changed Successfully");
        setFile_status(e.target.value);
        if (e.target.value == 3 || e.target.value == 4 || e.target.value == 6) {
          getNotes(record);
        }
        // } else {
        //   getData();
        //   check();
        // }
        setTimeout(() => {
          setflag(!flag);
        }, 100);
      })
      .catch(() => {
        console.log("status not saved");
      });
  };
  function formatDate(dateStr) {
    if (dateStr != "" && dateStr != null && dateStr != undefined) {
      const date = new Date(dateStr);
      return format(date, "MM/dd/yyyy hh:mm a");
    } else {
      return "";
    }
  }
  const onChangePurveyorRawName = (values) => {
    setPurveyorRawName(values.target.value);
    InvoiceDatas.PurveyorRawName = values.target.value;
    console.log("Purveyor", InvoiceDatas);
  };
  const handleInputChange = (event) => {
    if (event.target.value != "") {
      setinvoiceNumber(event.target.value);
      InvoiceDatas.invoice_number = event.target.value;
    } else {
      setinvoiceNumber("");
    }
    console.log("invoiceNumber", InvoiceNumber, InvoiceDatas.invoice_number);
  };
  const handledateInputChange = (event) => {
    if (event.target.value != "") {
      setInvoiceDate(event.target.value);
      InvoiceDatas.invoice_date = event.target.value;
    }
    //   alert(event.target.value)
    //   alert(formatDateforInvoiceDate(invoice_date))
    //   setInvoiceDate(event.target.value)
    //  if(formatDateforInvoiceDate(invoice_date)!=event.target.value){
    //   alert("true");
    // }else{
    //    alert('false');
    //   }
  };
  return (
    <>
      <div class="col-md-12 mb-4">
        <div class="container_card">
          <div
            class={
              (invSource == "Invoice Upload" && "carddatasysco") ||
              (invSource == "Invoice Email" && "carddatasysco") ||
              (invSource == "Split Invoice" && "carddatasysco") ||
              (invSource == "Merge Invoice" && "carddatasysco") ||
              (invSource == "Sysco EDI" && "carddatainvoice") ||
              (invSource == "PFG EDI" && "carddatainvoice") ||
              (invSource == "McLane EDI" && "carddatainvoice") ||
              (invSource == "Ben E. Keith EDI" && "carddatainvoice") ||
          (invSource == "US Foods EDI" && "carddatainvoice") ||
              (invSource == "" && "carddata")
            }
            id="ribbon"
            data-label={invSource ? invSource : "----"}
          >
            <div class="card__container">
              <div class="row" style={{ paddingTop: "0.5em" }}>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row mb-3 mb-lg-2 ">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Restaurant</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2 className="mapsizer">
                        {records?.ReviewData?.company}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row mb-2 mb-lg-1">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Invoice No</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <Input
                        type="text"
                        value={InvoiceNumber ? InvoiceNumber : ""}
                        onChange={(e) => handleInputChange(e)}
                        style={{
                          height: "90%",
                          color: "#5c5757",
                          width: "95%",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      />
                    </div>
                  </div>
                </div>


                {records?.ReviewData?.Account_Name !== "" ? (
              <div className="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                <div className="row mb-3 mb-lg-2">
                  <div className="col-xs-5 col-sm-5 col-md-5">
                    <h2 className="mapsize">Department</h2>
                  </div>
                  <div className="col-xs-1 col-sm-1 col-md-1">
                    <h2 className="mapsize">:</h2>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <h2 className="mapsizer">
                      {records?.ReviewData?.Account_Name}
                    </h2>
                  </div>
                </div>
              </div>
            ) : null}


                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6 mb-3">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Date & Time</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <h2 className="mapsizer">
                        {formatDate(records?.ReviewData?.date)}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-6 col-sm-12">
                  <div class="row mb-3 mb-lg-2">
                    <div class="col-xs-5  col-sm-5 col-md-5">
                      <h2 className="mapsize">Total </h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6 p-0 m-0">
                      <h2
                        style={{
                          fontSize: "14px",
                          color: "#5c5757",
                          margin: "0",
                          marginTop: "0.2em",
                          marginLeft: "1em",
                        }}
                      >
                        $ {total ? total?.toFixed(2) : 0.0}
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
              <div class="row mb-2 align-items-center">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Invoice Status</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <div class="row">
                        <div
                          class="col-xs-8  col-sm-8 col-md-8"
                          style={{ paddingRight: "0em" }}
                        >
                          <select
                            disabled={(file_status == "7" || file_status == "2") ? true : false}
                            SelectedValue={file_status}
                            value={file_status}
                            className="form-control"
                            style={{ fontSize: "14px", color: "#5c5757", fontWeight: '500', marginTop: '-5px' }}
                            onChange={(e) => {
                              handlechangeStatus(e);
                            }}
                          >
                            {items.map((x) => {
                              return <option value={x.key} disabled={(x.key == "3" && (invSource == "PFG EDI" || invSource == "Sysco EDI" || invSource == "McLane EDI" || invSource == "Ben E. Keith EDI" || invSource == "US Foods EDI")) ? true : x.disabled}>{x.label}</option>;
                            })}
                          </select>
                        </div>
                        {file_status == 2 && (
                          <div
                            class="col-xs-2 col-sm-2 col-md-2"
                            style={{ paddingLeft: "0em", textAlign: "left" }}
                          >
                            {qa_review == "1" && (
                              <spn>
                                {" "}
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  style={{
                                    color: "#1bb143",
                                    marginLeft: "0.5em",
                                    fontSize: "20px",
                                    // paddingTop: "3px",
                                    paddingRight: "20px",
                                    verticalAlign: '-0.42em',
                                  }}
                                />
                              </spn>
                            )}
                            {qa_review == "2" && (
                              <spn>
                                {" "}
                                <FontAwesomeIcon
                                  icon={faCircleXmark}
                                  style={{
                                    color: " #ff4d4d",
                                    marginLeft: "0.5em",
                                    fontSize: "20px",
                                    // paddingTop: "3px",
                                    verticalAlign: '-0.4em',
                                    paddingRight: "20px",
                                  }}
                                />
                              </spn>
                            )}
                          </div>
                        )}
                        {(file_status == 3 ||
                          file_status == 4 ||
                          file_status == 6 || (file_status == 2 && qa_review == "2")) && (
                            <div
                              class="col-xs-2 col-sm-2 col-md-2"
                              style={{ paddingLeft: "0em", textAlign: "left" }}
                            >
                              <span onClick={() => getNotes(record)}>
                                <Tooltip title="Edit">
                                  <BorderColorIcon
                                    style={{
                                      color: "rgb(0, 173, 255)",
                                      cursor: "pointer",
                                      fontSize: "1.4em",
                                      marginLeft: "0.5em",
                                      verticalAlign: '-0.38em'
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Invoice Date</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <input
                        className="date-pck-inc-ai"
                        min={record?.ReviewData?.Budget_type != 2 ? moment(new Date(invoiceData?.data?.Budget_year)).format('YYYY-MM-DD'): invoiceData?.data?.Budget_year + "-01-01"}
                        max={new Date().getFullYear() + "-12-31"}
                        type="date"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        onChange={handledateInputChange}
                        value={
                          invoiceDate
                          // ? formatDateforInvoiceDate(invoiceDate)
                          // : ""
                        }
                        disabled={file_status == 2}
                        style={{
                          height: "72%",
                          color: "#5c5757",
                          width: "95%",
                          fontSize: "14px",
                          fontWeight: '500',
                          marginTop: '-5px',
                        }}
                      />
                    </div>
                  </div>
                </div>
      
                <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
                  <div class="row" style={{ marginBottom: "1em" }}>
                    <div class="col-xs-5 col-sm-5 col-md-5">
                      <h2 className="mapsize">Purveyor</h2>
                    </div>
                    <div class="col-xs-1 col-sm-1 col-md-1">
                      <h2 className="mapsize">:</h2>
                    </div>
                    <div class="col-xs-6  col-sm-6 col-md-6">
                      <Input
                        type="text"
                        value={PurveyorRawName ? PurveyorRawName : ""}
                        onChange={(e) => onChangePurveyorRawName(e)}
                        style={{
                          height: "90%",
                          color: "#5c5757",
                          width: "95%",
                          fontSize: "14px",
                          fontWeight: '500'
                        }}
                        title={PurveyorRawName ? PurveyorRawName : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card__body">
                <div class="row">
                  <div
                    className={
                      pDisabled
                        ? "col-xs-12 col-sm-12 col-md-2 text-left"
                        : "col-xs-12 col-sm-12 col-md-2 text-left"
                    }
                  >
                    <span className={pDisabled ? "disable" : ""}>
                      <Tooltip title="Previous Review">
                        <Button
                          className={
                            pDisabled
                              ? "btn btn-success disable"
                              : "btn btn-success"
                          }
                          disabled={pDisabled}
                          style={{
                            border: "rgb(25, 167, 63)",
                            borderRadius: "70%",
                            marginRight: "1em",
                            marginTop: "1em",
                            padding: "5px 12px",
                            background: "#19a73f",
                          }}
                          onClick={() => {
                            if (records.index == 1) {
                              nextAndPrevious(0, 0);
                            } else {
                              nextAndPrevious(0, records.index);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </Button>
                      </Tooltip>
                    </span>
                  </div>
                  <div
                    class="col-xs-12 col-sm-12 col-md-8 container d-flex"
                    style={{ justifyContent: "center" }}
                  >
                    {(invSource !== "Sysco EDI" && invSource !== "PFG EDI" && invSource !== "McLane EDI" && invSource !== "Ben E. Keith EDI" && invSource !== "US Foods EDI" && file_status != 2) ? (
                      <div
                        className="row d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        <div className="aimenu">
                          <span
                            className="aimenuitem tooltip tooltiper"
                            data-tooltip="Delete Invoice"
                            data-tooltip-pos="upper"
                            data-tooltip-length="medium"
                          >
                            <FontAwesomeIcon
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                              icon={faTrashCan}
                              onClick={(e) => {
                                Deletepopup(
                                  "delete",
                                  record.ReviewData.Upload_id,
                                  record.index
                                );
                              }}
                            />
                          </span>
                          <span
                            class="aimenuitem1 tooltip tooltiper"
                            data-tooltip="Rescan Invoice"
                            data-tooltip-pos="upper"
                            data-tooltip-length="medium"
                          >
                            <ScanOutlined
                              style={{
                                fontSize: "20px",
                                marginTop: "-10px",
                                cursor: "pointer",
                                color: "#2196f3",
                              }}
                              onClick={() => {
                                if (records) {
                                  reFreshFUnction(records);
                                }
                              }}
                            />
                          </span>
                          <span
                            class="aimenuitem1 tooltip tooltiper"
                            data-tooltip="View Invoice"
                            data-tooltip-pos="upper"
                            data-tooltip-length="medium"
                          >
                            <RemoveRedEyeIcon
                              style={{
                                color: "rgb(25, 167, 63)",
                                fontSize: "24px",
                                cursor: "pointer",
                              }}
                              onClick={viewinvoicee}
                            />
                          </span>
                          <span
                            class="aimenuitem1 tooltip tooltiper"
                            style={{ paddingRight: "0" }}
                            data-tooltip="Split Invoice"
                            data-tooltip-pos="upper"
                            data-tooltip-length="medium"
                          >
                            <InsertPageBreakOutlinedIcon
                              style={{
                                color: "#FF9800",
                                fontSize: "22px",
                              }}
                              onClick={split_pdf}
                            />
                          </span>
                          {showDeleteIcon && (
                            <span
                              class="aimenuitem2 tooltip tooltiper"
                              style={{ marginRight: "0", marginLeft: "0.5em" }}
                              data-tooltip="Remove Items"
                              data-tooltip-pos="uppers"
                              data-tooltip-length="medium"
                            >
                              <RemoveShoppingCartIcon
                                style={{ color: "#FF5555", cursor: "pointer" }}
                                icon={faTrashCan}
                                onClick={() => setShowmultidelete(true)}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="row d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        <div className="aimenu">
                          <span
                            className="aimenuitem tooltip tooltiper"
                            data-tooltip="Delete Invoice"
                            data-tooltip-pos="upper"
                            data-tooltip-length="medium"
                            style={{ marginRight: "0em" }}
                          >
                            <FontAwesomeIcon
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                              icon={faTrashCan}
                              onClick={(e) => {
                                Deletepopup(
                                  "delete",
                                  record.ReviewData.Upload_id
                                );
                              }}
                            />
                          </span>
                          <span
                            class="aimenuitem1 tooltip tooltiper"
                        data-tooltip="Rescan Invoice"
                        data-tooltip-pos="upper"
                        data-tooltip-length="medium"
                      >
                        <ScanOutlined
                          style={{
                            fontSize: "20px",
                            marginTop: "-10px",
                            cursor: "pointer",
                            color: "#2196f3",
                          }}
                          onClick={() => {
                            if (records) {
                              reFreshFUnction(records);
                            }
                          }}
                        />
                      </span>
                      <span
                        class="aimenuitem1 tooltip tooltiper"
                            data-tooltip="View Invoice"
                            data-tooltip-pos="upper"
                            data-tooltip-length="medium"
                        style={{ marginRight: '-0.5em !important' }}
                          >
                            <RemoveRedEyeIcon
                              style={{
                                color: "rgb(25, 167, 63)",
                                fontSize: "24px",
                                cursor: "pointer",
                              }}
                              onClick={viewinvoicee}
                            />
                          </span>
                          {showDeleteIcon && (
                            <span
                              class="aimenuitem2 tooltip tooltiper"
                              data-tooltip="Remove Items"
                              data-tooltip-pos="upper"
                              data-tooltip-length="medium"
                              style={{
                                marginRight: "0em",
                                marginLeft: "0.5em",
                              }}
                            >
                              <RemoveShoppingCartIcon
                                style={{ color: "#FF5555", cursor: "pointer" }}
                                icon={faTrashCan}
                                onClick={() => setShowmultidelete(true)}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      nDisabled
                        ? "col-xs-12  col-sm-12 col-md-2 p-0 "
                        : "col-xs-12  col-sm-12 col-md-2 p-0"
                    }
                    style={{ textAlign: "right" }}
                  >
                    <span className={nDisabled ? "disable" : ""}>
                      <Tooltip title="Next Review">
                        <Button
                          className={
                            nDisabled
                              ? "btn btn-success disable"
                              : "btn btn-success"
                          }
                          disabled={nDisabled}
                          onClick={() => {
                            if (records.index == tdata.length - 2) {
                              nextAndPrevious(1, records.index + 1);
                            }
                            // else if(records.index==0){
                            //   nextAndPrevious(1,1);
                            // }
                            else {
                              nextAndPrevious(1, records.index);
                            }
                          }}
                          style={{
                            border: "rgb(25, 167, 63)",
                            borderRadius: "70%",
                            marginRight: "1em",
                            marginTop: "1em",
                            padding: "5px 12px",
                            background: "#19a73f",
                          }}
                        >
                          <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* multidelete */}
      <Modal
        style={{ marginTop: "6em" }}
        show={showmultidelete}
        onHide={() => setShowmultidelete(false)}
        top
      >
        <Modal.Header
          style={{
            backgroundColor: "rgb(25, 167, 63)",
            height: "45px",
            borderRadius: "3px",
          }}
        >
          <Modal.Title>
            <h5 style={{ fontSize: "22px", marginBottom: "0" }}>
              <FontAwesomeIcon icon={faTriangleExclamation} /> Warning
            </h5>
          </Modal.Title>
          <span
            className="close"
            onClick={() => {
              setShowmultidelete(false);
            }}
          >
            <span class="closeicon">&times;</span>
          </span>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          Do you want to delete selected line items?
        </Modal.Body>
        <Modal.Footer style={{ borderStyle: "none", justifyContent: "center" }}>
          <Button
            variant="danger"
            style={{
              background: "#9b9b9f",
              border: "none",
              borderRadius: "3px",
            }}
            onClick={() => setShowmultidelete(false)}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            style={{
              background: "#19A73F",
              border: "none",
              borderRadius: "3px",
            }}
            onClick={() => {
              Multideleteitem();
              setShowmultidelete(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MappingTopBar;
