import { Navigate } from "react-router-dom";
import { LocalStorageService } from '../Services/LocalStorageService';

export const PrivateRoute = ({ children }) => {

    const localStorageService = LocalStorageService.getService();

    const token = localStorageService.getAccessToken();

    if (!token) {
        // user is not authenticated
        return <Navigate to="/" />;
    }
    return children;
};