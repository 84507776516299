import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CommonTable from '../CommonTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Drawer, Input, Table, Switch, Form, Tooltip, Modal, Select } from 'antd';
import { fobeAxios } from '../../middleware/interceptor';
import { toast } from "react-toastify";
function RoleAccess() {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const [open, setOpen] = useState(false);
  const [RoleData, setRoleData] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [ColumnList, setColumnList] = useState([]);
  const [NewRoleSaveKey, setNewRoleSaveKey] = useState('')
  const [change_list, setChange_list] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [listDetails, setListDetails] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("SpecialUser"));
  useEffect(() => { ; GetRoleList(); GetRoleAccessList() }, []);


  const onClose = () => { setOpen(false); };
  const showDrawer = () => { setOpen(true); };
  //Get the Role list
  const GetRoleList = async () => {
    await fobeAxios.post('api/Admin/getRolelist').then((res) => {
      let templist = [];
      templist = res.data.Role_list.map((data) => ({
        key: data.Role_id,
        Role_name: data.Role_name,
        status: data.Status
      }));
      setRoleList(templist);
      setLoading(false)
    }).catch((err) => {
      console.log(err)
    });
  }
  // Make The Table Cell Editable
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps} style={{ width: '50%' }}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Enter ${title}!`,
              },
            ]}

          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  //Get Role Access List
  const GetRoleAccessList = async () => {
    await fobeAxios.get("api/Admin/getRoleaccess").then((res) => {
      setRoleData([...res.data.Roleaccess_list]);
      setColumnList([...res.data.Table_columns]);

    }).catch((err) => {
      console.log(err)
    })
  }

  // Columns rendering for RoleAccess Table
  const columns = [
    {
      accessorKey: "Role",
      header: 'Modules/Roles',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      size: 250,
      // enableHiding: false,
    },
    ...ColumnList.map((data) => ({
      accessorKey: data.field,
      header: data.column_name,
      editable: false,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      size: 200,
      // enableHiding: false,
      Cell: ({ cell, row }) => {
        return (
          <Checkbox
            color="success"
            checked={row.original[data.field]}
            disabled={AccessKey === '999'}
            style={{ cursor: AccessKey === '999' ? 'not-allowed' : 'pointer' }}
            onClick={(e) => handleRoleAccess(row.original, e, cell)}
          />
        );
      },

    }))

  ];

  //Get the Index of Role Access
  const handleRoleAccess = (res, e, cell) => {
    let temp = RoleData.map((data) => {
      return data.Role === res.Role ? { ...data, [cell.column.id]: e.target.checked ? 1 : 0 } : data
    });
    let t = [...change_list]; // Create a shallow copy of the array to avoid mutating the original array
    const existingIndex = t.findIndex(item => item.Role_name === cell.column.id && item.Module_name === res.Role); // Find the index of the existing object in the array based on some condition
    if (existingIndex !== -1) { // Check if the object exists in the array
      t[existingIndex] = { ...t[existingIndex], Access: e.target.checked ? 1 : 0 }; // Update the existing object's properties
    } else {
      t.push({ "Role_name": cell.column.id, "Module_name": res.Role, "Access": e.target.checked ? 1 : 0 }); // Add a new object to the array
    }
    setRoleData(temp);
    setChange_list(t)
  };

  //Save The Role Access
  const UpdateRoleAccess = async () => {
    let test = { "Access_list": [...change_list] }
    await fobeAxios.post("api/Admin/UpdateRoleAccess", test).then((res) => {
      if (res.data.status) {
        toast.success("Role Access updated");
      } else {
        toast.error("Role Access is not updated");
        GetRoleAccessList();
      }
      setChange_list([]);
    }).catch((err) => {
      console.log(err)
    })
  }

  //RoleName Color
  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor.toString();
  }
  // Columns rendering for RoleManagement table
  const columns1 = [
    {
      title: 'Role',
      dataIndex: 'Role_name',
      key: 'Role_name',
      align: 'left',
      editable: true,
    },
    // {
    //   title: 'Switch',
    //   dataIndex: 'status',
    //   key: 'switch',
    //   align: 'center',
    //   render: (text,row) => {
    //     // console.log(text,row)
    //   //  return <Switch size="small" checked={text} onChange={()=>{changeRoleAccessStatus(row)}}  />
    // },
    // },
    {
      title: 'Edit',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (<div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
          <CheckCircleIcon onClick={() => { AddRole(record) }}
            style={{ color: '#6A8AB9', cursor: 'pointer' }} />
          <CancelIcon onClick={cancel}
            style={{ color: '#F86764', cursor: 'pointer' }} />
        </div>
        ) : (
          <div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
            <Tooltip title="Edit">
              <EditIcon onClick={() => record.key > 103 ? edit(record) : ''}
                style={{ color: '#6A8AB9', cursor: record.key > 103 ? 'pointer' : 'not-allowed' }} />
            </Tooltip>
            <Tooltip title="Delete" onClick={() => record.key > 103 ? showDeletePopup(record) : ''}>
              <DeleteOutlineOutlinedIcon
                style={{ color: '#F86764', cursor: record.key > 103 ? 'pointer' : 'not-allowed' }} />
            </Tooltip>
          </div>)
      },
    }
  ];

  // Delete the User Role
  const showDeletePopup = (record) => {
    setDeletePopup(true);
    setListDetails(record);
  }

  const deleteUserHandler = async (record) => {
    try {
      const response = await fobeAxios.get(`api/Admin/deleteRoleandShiftusers?role_to_be_deleted=${record.key}&shifting_role=${selectedRole}`);
      console.log(response?.data?.status);
      if (response?.data?.status) {
        GetRoleList();
        GetRoleAccessList();
      }
      setDeletePopup(false);
    } catch (err) {
      console.log(err);
      setDeletePopup(false);
    }
  }

  // Edit the Cell inside the table
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
    // setNewRoleSaveKey('exist');
  };

  // Cancel the Changes
  const cancel = () => {
    if (NewRoleSaveKey == 'New') {
      let temp = RoleList;
      temp.splice(temp.length - 1, 1);
      setRoleList([...temp]);
      setNewRoleSaveKey('');
      setEditingKey('');
    } else {
      setEditingKey('');
    }
  }

  //Save the Changes
  const AddRole = async (key) => {

    const row = await form.validateFields();
    console.log(key, row);
    let req = {
      "role_id": key.key == 1 ? 0 : key.key,
      "Rolename": row.Role_name,
      "color_code": setBg()
      // "role_status": 1
    }
    await fobeAxios.post("api/Admin/AddRoleaccess1", req).then((res) => {
      if (res.data.status == 1) {
        GetRoleList();
        GetRoleAccessList();
        setEditingKey('');
        setNewRoleSaveKey('');
      } else {
        form.setFields([
          {
            name: 'Role_name',
            errors: ['This role already exists'],
          },
        ]);
      }
    }).catch((err) => {
      console.log(err)
    })
  };

  //Add New Role 
  const AddNewRole = () => {
    let temp = [...RoleList];
    temp.push(
      {
        "key": 1,
        "Role_name": "",
        "status": 0
      }
    )
    setRoleList(temp)
    setNewRoleSaveKey('New');
    edit(temp[temp.length - 1]);
  }

  //Columns render When the table cell is editable
  const mergedColumns = columns1.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const optionsList = RoleList.filter(item => {
    return item.key !== listDetails.key;
  })

  const options = optionsList.map(role => {
    return { label: role.Role_name, value: role.key }
  });

  const manageFilter = [
    <div className='forbuttonon1'>
      <Button type="primary" disabled={AccessKey === '999'} onClick={showDrawer}>Manage Role</Button>
    </div>

  ]

  return (
    <>
      <div className='useertable'>
        <CommonTable
          rows={RoleData}
          columns={columns}
          table={"role"}
          isLoading={loading}
          HiddenColumnlist={{}}
          headFilter={manageFilter}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: '0',
        width: '100%', // This ensures the div spans the entire width of the viewport
        padding: '15px',
        background: '#fff',
        left: '0%',
        //  zIndex: '999'
      }}>
        <div className='row'>
          <div className='col-md-6'>
            <Button type="primary" disabled={AccessKey === '999'} onClick={UpdateRoleAccess} style={{ backgroundColor: '#19A73F' }}>Save</Button>
          </div>
          <div className='col-md-6'>
            <Button style={{
              backgroundColor: 'rgb(239 239 239)',
              color: '#000',
              borderRadius: '3px',
              border: '0.5px solid #b2b2b2',
            }} disabled={AccessKey === '999'} onClick={GetRoleAccessList}>Cancel</Button>
          </div>
        </div>

      </div>
      <Drawer
        title="Manage Role"
        width={550}
        onClose={onClose}
        open={open}
        closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}
      >
        <div className='newroledraw'>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={RoleList}
              columns={mergedColumns}
              showHeader={false}
              pagination={false}
            />
          </Form>
        </div>
        <div className='addplus'>
          <Button type="link" className='pluslink' disabled={editingKey != ''} onClick={AddNewRole}> <AddCircleIcon style={{ color: '#19A73F', marginTop: '-4px' }} />&nbsp;&nbsp;Add New Role</Button>
        </div>
      </Drawer>
      <Modal
        title="Delete User"
        open={deletePopup}
        onCancel={() => { setDeletePopup(false) }}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              onClick={() => { setDeletePopup(false) }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#19A73F',
                color: '#fff',
                borderRadius: '3px',
                border: 'none'
              }}
              onClick={() => deleteUserHandler(listDetails)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p style={{ fontSize: '15px', textAlign: 'center', padding: '10px 20px 20px 20px' }}>Before you delete the role please select the role to assign the role</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            style={{
              width: '80%',
              margin: '10px 0'
            }}
            placeholder="Select Role"
            options={options}
            value={selectedRole}
            onChange={(e) => { setSelectedRole(e) }}
          />
        </div>
      </Modal>
    </>
  );
}
export default RoleAccess;
