import axios from "axios";
import { LocalStorageService } from '../Services/LocalStorageService';
import { history } from '../Services/history';



export const fobeAxios = axios.create({


  ///////////////////////  FOBESOFT LIVE     ////////////////////////////

    //  baseURL:'https://api.fobesoft.com'
    baseURL: 'https://fobeapi.fobesoft.com',



///////////////////////   FOBESOFT DEV    ///////////////////////////////

//    baseURL:   'https://apitest74.fobesoft.com/'    



});

export const setAxiosDefauls = () => {

    fobeAxios.interceptors.request.use(
        onRequestFulfilled
        , onRequestRejected);

    fobeAxios.interceptors.response.use(
        onResponseFulfilled
        , onResponseRejected);

    fobeAxios.interceptors.request.use(
        onRequestFulfilled
        , onRequestRejected);

    fobeAxios.interceptors.response.use(
        onResponseFulfilled
        , onResponseRejected);
}

export const resetSession = () => {
    localStorage.remove("auth-token");
    history.push('/');
}

const onRequestFulfilled = (config) => {
    const localStorageService = LocalStorageService.getService();
    const token = localStorageService.getAccessToken("auth-token");
    if (token) {
        config.headers = {
            "Authorization": "Bearer " + token,
            "Access-Control-Allow-Origin": "*"
        };
    }
    else {
        config.headers = {
           
            //   "Cache-Control": "no-cache"
            //  ,"Access-Control-Allow-Origin": "*"
            //  ,"Content-Type":"application/json"
            //  , "Pragma": "no-cache"
            //  , "X-Frame-Options": "SAMEORIGIN",
        };
    }
    return Promise.resolve(config);
}

const onRequestRejected = (error) => {
    return Promise.reject(error);
}

const onResponseFulfilled = (config) => {
    return Promise.resolve(config);
}

const onResponseRejected = (error) => {
    return Promise.reject(error);
}