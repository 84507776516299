import React from "react";
import { Table, Space, Input } from "antd";
import { useState } from "react";

const DataTable2 = ({
  columns,
  tableData2,
  handleTableChange,
  LoadingReviewInvoice,
  sorter,
  filters,
  loading
}) => {


  const showTotal = (total, range) => {
    return (
      <div style={{ textAlign: "right" }}>
        Showing {range[0]} to {range[1]} of {total} entries
      </div>
    );
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData2}
        pagination={false}
        loading={LoadingReviewInvoice}
        showSorterTooltip={false}
        onChange={handleTableChange}
        // showsizeChanger={true}
        scroll={{ x: 1000, y: 400 }}
        rowClassName={record => !record.enabled && "disabled-row"}
      />
    </>
  );
};

export default DataTable2;