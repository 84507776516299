import React, { useState } from 'react';
import Header from "../../Header/header";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../User.css";
import CommonTable from '../CommonTable';
import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import Checkbox from '@mui/material/Checkbox';
import { fobeAxios } from '../../middleware/interceptor';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Typography from '@mui/material/Typography';

function AddRestaurant() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [restaurantData, setRestaurantData] = useState([]);
  const [modalRestaurantData, setModalRestaurantData] = useState([]);
  const [modalSelectedRecord, setModalSelectedRecord] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [modalCheckedItems, setModalCheckedItems] = useState({});
  const [loading1, setLoading1] = useState(true);
  const [loading, setLoading] = useState(true);
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("SpecialUser"));
  let navigate = useNavigate();
  const { groupId, groupName } = useParams();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.clear();
    navigate("/");
  };
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const onChange = (CheckboxChangeEvent) => {
    console.log(`checked`);
  };
  const [open, setOpen] = useState(false);
  const onClose = () => { setOpen(false); };
  const showDrawer = () => { setOpen(true); };

  useEffect(() => {
    // const getRestDetails = async () => {
    fetchRestaurantDetails();
    fetchFullRestDetails();
    // }
    // getRestDetails();
  }, [])
  const fetchRestaurantDetails = async () => {
    await fobeAxios.get(`Api/Admin/GetHqRest/${groupId}`).then((res) => {
      setLoading(false)
      setRestaurantData(res?.data?.Hq_list);
    }).catch((err) => {
      setLoading(false)
      console.log(err);
    });
    //   response.data ? setLoading(false) : ''; 
    //   return response?.data?.Hq_list;
  }

  const fetchFullRestDetails = async () => {
    await fobeAxios.get(`Api/Admin/GetAddRest/${groupId}`).then((res) => {
      setLoading1(false);
      setModalRestaurantData(res?.data?.Hq_list);
    }).catch((err) => {
      setLoading1(false);
    })
    //   return response?.data?.Hq_list;
  }

  const updateRestaurant = (rowId, value) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [rowId]: value,
    }));
    if (value) {
      setSelectedRecord([...selectedRecord, rowId]);
    } else {
      const filteredRecord = selectedRecord?.filter(item => item !== rowId);
      setSelectedRecord(filteredRecord);
    }
  }
  const updateModalRestaurant = (rowId, value) => {
    console.log(modalCheckedItems)
    setModalCheckedItems((prevState) => ({
      ...prevState,
      [rowId]: value,
    }));
    if (value) {
      setModalSelectedRecord([...modalSelectedRecord, rowId]);
    } else {
      const filteredRecord = modalSelectedRecord?.filter(item => item !== rowId);
      setModalSelectedRecord(filteredRecord);
    }
  }



  useEffect(() => {
    const initialCheckedState = {};
    const restIds = [];
    restaurantData?.forEach((item) => {
      initialCheckedState[item.Rest_id] = true;
      restIds.push(item.Rest_id);
    });
    setSelectedRecord(restIds);
    setCheckedItems(initialCheckedState);
  }, [restaurantData]);

  const rows1 = modalRestaurantData?.map(item => {
    return {
      select: item?.Rest_id,
      res_name: item?.Rest_name,
      email: item?.Email
    }
  });

  const columns1 = [
    {
      accessorKey: "select",
      header: 'Select',
      editable: false,
      headerAlign: 'center',
      enableColumnFilter: false,
      align: 'center',
      Cell: (row) => (
        <>
          <div className='checkbox-sec'>
            <Checkbox
              checked={modalCheckedItems[row?.renderedCellValue] || false} disabled={AccessKey === '999'}
              onChange={(e) => updateModalRestaurant(row?.renderedCellValue, e.target.checked)}
            />
          </div>
        </>
      ),
    },
    {
      accessorKey: "res_name",
      header: 'Restaurant Name',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      accessorKey: "email",
      header: 'Email Id',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];


  const rows = restaurantData?.map(item => {
    return {
      select: item?.Rest_id,
      res_name: item?.Rest_name,
      email: item?.Email
    }
  });

  const columns = [
    {
      accessorKey: "select",
      header: 'Select',
      editable: false,
      headerAlign: 'center',
      enableColumnFilter: false,
      align: 'center',
      Cell: (row) => (
        <>
          <div className='checkbox-sec'>
            <Checkbox
              checked={checkedItems[row?.renderedCellValue] || false} disabled={AccessKey === '999'}
              onChange={(e) => updateRestaurant(row?.renderedCellValue, e.target.checked)}
            />
          </div>
        </>
      ),
    },
    {
      accessorKey: "res_name",
      header: 'Restaurant Name',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      Cell: ({ row }) => {
        return <span style={{ color: "#19A73F" }}>{row.original.res_name}</span>
      }
    },
    {
      accessorKey: "email",
      header: 'Email Id',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const addRestaurantHandler = async () => {
    const addRest_id = {};
    modalSelectedRecord?.forEach((item, index) => {
      addRest_id[index] = item;
    })
    const payload = {
      "Rest_id": groupId,
      addRest_id
    }
    console.log(modalSelectedRecord)
    if (modalSelectedRecord.length > 0) {
      await fobeAxios.post("/Api/Admin/AddRest", payload).then((res) => {
        if (res?.data == 1) {
          fetchRestaurantDetails();
          fetchFullRestDetails();
          setModalCheckedItems({});
          toast.success("Restaurant Added Successfully")
        } else {
          toast.error("Failed to Add Restauant")
        }
      }).catch((err) => {
        console.log(err)
      })
    } else {
      toast.warning("Please Select the Restaurant")
    }

    // return response?.data;
  }
  const updateRestaurantHandler = async () => {
    const addRest_id = {};
    selectedRecord?.forEach((item, index) => {
      addRest_id[index] = item;
    })
    const payload = {
      "Rest_id": groupId,
      addRest_id
    }
    await fobeAxios.post("Api/Admin/UpdateRest", payload).then((res) => {
      if (res?.data == 1) {
        toast.success("Updated Successfully");
        fetchRestaurantDetails();
        fetchFullRestDetails();
        setModalCheckedItems({});
      } else {
        toast.error("Failed to Update");
      }
    }).catch((err) => {
      console.log(err)
    })
    // return response?.data;
  }
  const handleAdd = async () => {
    const addedResponse = await addRestaurantHandler();
    if (addedResponse) {
      const fetchedRestDetails = await fetchRestaurantDetails();
      setRestaurantData(fetchedRestDetails);

      const fetchedFullRestDetails = await fetchFullRestDetails();
      setModalRestaurantData(fetchedFullRestDetails);
    }
    setModalCheckedItems({});
  }
  const cancelHandler = () => {
    navigate('/hq')
  }
  const saveHandler = async () => {
    const updatedResponse = await updateRestaurantHandler();
    if (updatedResponse) {
      const fetchedRestDetails = await fetchRestaurantDetails();
      setRestaurantData(fetchedRestDetails);

      const fetchedFullRestDetails = await fetchFullRestDetails();
      setModalRestaurantData(fetchedFullRestDetails);
    }
  }


  const addrestFilter = [

    <div
      className='forbuttonon'
    >
      <Button
        type="primary"
        disabled={AccessKey === '999'}
        onClick={showModal}>Add Restaurant</Button>




      <Modal
        className='overhq'
        style={{
          top: 20,
        }}
        open={isModalVisible}
        title="HQ Name"
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
            <Button
              className='save-button'
              type='button'
              onClick={addRestaurantHandler}
            >
              Add
            </Button>
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2',
              }}
              type='button'
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        ]}
      >
        <div className='col-md-12 popup-table' style={{ marginTop: '50px' }}>
          <CommonTable
            rows={rows1 ? rows1 : []}
            columns={columns1}
            table={"AddRestaurant"}
            isLoading={loading}
            HiddenColumnlist={{}}
          />
        </div>
      </Modal>



    </div>

  ]


  const addRestaurant = [

    <>

      <div className='content-container'>

        <div className='containerford'>
          <div className='row'>
            <div role="presentation" onClick={handleClick} style={{ marginTop: '10px' }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/hq" style={{ color: '#19A73F', textDecoration: 'none', fontSize: '14px' }}>
                  <HomeIcon sx={{ mr: 0.5, mt: -0.5, width: '15px', height: '15px' }} />
                  HQ
                </Link>
                <Typography style={{ color: '#000', fontSize: '14px' }}><ManageAccountsIcon sx={{ mr: 0.5, mt: -0.5, fontSize: '15px', width: '15px', height: '15px' }} />{groupName}</Typography>
              </Breadcrumbs>
            </div>
          </div>


          <div className='first_conteent' style={{ marginTop: '4em' }}>
            <CommonTable
              rows={rows ? rows : []}
              columns={columns}
              isLoading={loading1}
              table={"user"}
              HiddenColumnlist={{}}
              headFilter={addrestFilter}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'fixed',
                bottom: '0',
                width: '100%', // This ensures the div spans the entire width of the viewport
                padding: '15px',
                background: '#fff',
                left: '0%',
                // zIndex: '999'
              }}
            >
              <div className='row'>
                <div className='col-md-6'>
                  <Button
                    type="primary"
                    style={{ backgroundColor: '#19A73F' }}
                    onClick={updateRestaurantHandler}
                    disabled={AccessKey === '999'}
                  >
                    Save
                  </Button>
                </div>
                <div className='col-md-6'>
                  <Button style={{
                    backgroundColor: 'rgb(239 239 239)',
                    color: '#000',
                    borderRadius: '3px',
                    border: '0.5px solid #b2b2b2',
                  }} onClick={cancelHandler}>Back</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ]
  return (
    <div>
      <ToastContainer autoClose={1500} />
      <Header
        handleMobileMenuClose={handleMobileMenuClose}
        anchorEl={anchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        loginUserName={loginUserName}
        handleMenuCloseout={handleMenuCloseout}
        handleMobileMenuOpen={handleMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        element={addRestaurant}
      />
    </div>
  );
}


export default AddRestaurant;
