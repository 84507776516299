import React, { useEffect } from "react";
import ChatBubble from "./ChatBubble";
import "./ChatTranscript.css";
import { getDateLabel } from "../../utils/dateUtils";

const ChatTranscript = ({ messages, messageLimit }) => {
  const displayedMessages =  messageLimit
    ? messages?.slice(0, messageLimit)
    : messages;

  let lastDateLabel = null;
  // useEffect(() => {
  //   let test = messages?.slice(((messages.length - 1) - (messageLimit + 1)), ((messages.length - 1) - (messageLimit + 1)));
  //   console.log(test);
  // }, [])
  return (
    <div className="chat-transcript">
      {displayedMessages?.map((msg, index) => {
        const dateLabel = getDateLabel(msg.timestamp);
        const showDateLabel = dateLabel !== lastDateLabel;
        lastDateLabel = dateLabel;

        return (
          <React.Fragment key={index}>
            {showDateLabel && (
              <div className="chat-transcript__date-label">{dateLabel}</div>
            )}
            <ChatBubble
              message={msg.text}
              sender={msg.sender}
              msgStatus={msg.msgStatus}
              timestamp={msg.timestamp}
              index={index}
              msgDetails={msg}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ChatTranscript;
