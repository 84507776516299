import React, { useState, useEffect } from 'react';
import CommonTable from '../CommonTable';
import { DatePicker } from 'antd';
import { Cascader, Input } from 'antd';
import { fobeAxios } from '../../middleware/interceptor';
import dayjs from 'dayjs';
import * as moment from 'moment';
import { toast } from "react-toastify";
function BillingDetailsTable() {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("SpecialUser"));
  const [UserDetails, setUserDetails] = useState({})
  const [value, setValue] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => { GetUserDetails(); }, []);
  const fetchBillingDate = async (date, restId) => {
    const req = {
      "details": {
        "Next_Billing_Date": date,
        "rest_id": restId
      }
    }
    await fobeAxios.post('Api/Admin/UpdateNextBillingDate', req).then((res) => {
      let temp = UserDetails.map((data) => {
        return req.rest_id === data.Rest_id ? { ...data, Next_Billing_Date: req.Next_Billing_Date } : data;
      });
      setUserDetails(temp);
      res.data == 1 ? toast.success('Billing Date Changed successfully') : toast.error('Updated Failed')
    }).catch((err) => {
      console.log(err)
    })
  }

  const onChange = async (date, restId) => {
    const fetchedBillingDate = await fetchBillingDate(date, restId);
    console.log(fetchedBillingDate);
  };
  const { SHOW_CHILD } = Cascader;
  const options = [
    {
      label: 'Account_type',
      value: '1',
      children: [
        {
          label: 'FobeSoft',
          value: "1"
        },
        {
          label: 'FobeGolf',
          value: "5"
        },
        {
          label: 'Sysco',
          value: "3"
        },
      ],
    },
    {
      label: 'Status',
      value: '0',

      children: [
        {
          label: 'Active',
          value: '1',
        },
        {
          label: 'Deactive',
          value: '0',
        }

      ],
    },
  ];


  const change = (res) => {
    const date = new Date(res);

    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit', // Include seconds
      hour12: true
    });

    return formattedDate + ' ' + formattedTime;
  };

  const GetUserDetails = async () => {

    await fobeAxios.get("api/Admin/GetBillingInfo").then((res) => {
      setLoading(false)
      if (res.data.Billing_Details.length > 0) {
        const newjson = res.data.Billing_Details.map((item, index) => {
          return {
            ...item, Lastlogin: item.Lastlogin ? moment(item.Lastlogin).format('MM/DD/YYYY hh:mm A') : '-',
            Last_Billing_Date: moment(item.Last_Billing_Date.split('T')[0]).format('MM/DD/YYYY'),
            Next_Billing_Date: moment(item.Next_Billing_Date.split('T')[0]).format('MM/DD/YYYY'),
            Amount: currencyFormatter.format(item.Amount) === '$0.00' ? '-' : currencyFormatter.format(item.Amount)
          }
        })
        setUserDetails(newjson);
        setValue(newjson)
      }
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    });
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const dateFormat = 'MM/DD/YYYY';
  const columns = [
    {
      accessorKey: "Company",
      header: 'Company',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return <span style={{ color: "#19A73F" }}>{row.original.Company}</span>
      }
    },
    {
      accessorKey: "Owner_Name",
      header: 'Owner Name',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Lastlogin",
      header: 'Last Login',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      // Cell:({ row })=>{
      //   return row.original.Lastlogin ? row.original.Lastlogin:"-";
      // }
    },
    {
      accessorKey: "Last_Billing_Date",
      header: 'Last Billing Date',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "Amount",
      header: 'Amount',
      editable: false,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      // Cell:({ row })=>{
      //   const formattedValue = currencyFormatter.format(row.original.Amount);
      //   return formattedValue === '$0.00' ? '-' : formattedValue;
      // }
    },
    {
      accessorKey: "Next_Billing_Date",
      header: 'Next Billing Date',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <DatePicker
            disabled={AccessKey === '999'}
            onChange={(date, dateString) => onChange(dateString.replace(/\//g, "-"), row.original.Rest_id)}
            defaultValue={dayjs(row.original.Next_Billing_Date)}
            format={dateFormat}
            allowClear={false}
          />
        )
      }
    },
    {
      accessorKey: "Credit_Debit_Card",
      header: 'Card Details',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },

  ];





  const filters = (values) => {


    let updatearay = [];
    let empty = [];


    for (let i = 0; i < values.length; i++) {

      if (values[i][0] == 1) {

        const filteredData = UserDetails.filter(item => item.Account_id == values[i][1]);

        if (filteredData.length > 0) {
          updatearay = [...updatearay, ...filteredData]
          setValue(updatearay)
          setfilterdata(updatearay)


        }
        else {
          setValue([])
        }

      }
      if (values[i][0] == 0) {

        const filteredData = (updatearay.length > 0) ? updatearay.filter(item => item.Admin_status == values[i][1]) : UserDetails.filter(item => item.Admin_status == values[i][1]);


        console.log(filteredData, 'filteredData')
        if (filteredData.length > 0) {

          empty = [...empty, ...filteredData]
          setValue(empty)
        }
        else {
          setValue([])
        }
        if (values[i][1] == undefined) {
          const filteredData = (updatearay.length > 0) ? updatearay.filter(item => item.Admin_status == 1 || item.Admin_status == 0) : UserDetails.filter(item => item.Admin_status == 1 || item.Admin_status == 0);

          console.log(filteredData, 'filteredData11')
          if (filteredData.length > 0) {

            empty = [...empty, ...filteredData]
            setValue(empty)
          }
          else {
            setValue([])
          }
        }
      }


      if (values == 1) {

        const filteredData = UserDetails.filter(item => item.Account_id && [1, 2, 3, 5].includes(item.Account_id));
        console.log(filteredData, 'updatearay')

        if (filteredData.length > 0) {
          updatearay = [...updatearay, ...filteredData]

          setValue(updatearay)

        }
        else {
          setValue([])
        }
      }

      if (values == 0) {


        setValue(UserDetails)
      }



    }

    if (values.length == 0) {

      setValue(UserDetails)

    }
  }

  const billingFilter = [

    <div className='cascader'>
      <Cascader
        style={{ height: '38px', }}
        options={options}
        onChange={filters}
        multiple
        maxTagCount="responsive"
        placeholder="Filter By"
      />
    </div>
  ]

  return (
    <>


      <div className='useertable'>
        <CommonTable
          rows={value}
          columns={columns}
          table={"Billingdetails"}
          isLoading={loading}
          HiddenColumnlist={{}}
          headFilter={billingFilter}
        />
      </div>

    </>
  );
}
export default BillingDetailsTable;
