import React, { useState } from "react";
import { Modal, Box, Typography, TextField, IconButton } from "@mui/material";
import {
  AttachFile,
  Image,
  Send,
  EmojiEmotions,
  TextFormat,
  Padding,
} from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { useDropzone } from "react-dropzone";
import "./InstructionModal.css";
import ChatTranscript from "../components/chat/ChatTranscript";
import Header from "../Header/header";
import { useNavigate, Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 968,
  // maxWidth: "100%",
  // bgcolor: "background.paper",
  // borderRadius: "8px",
  // boxShadow: 24,
};

const InstructionModal = ({ open, onClose, messages, onSend }) => {
  let navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isRichText, setIsRichText] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const onDrop = (acceptedFiles) => {
    // console.log(acceptedFiles);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.clear();
    navigate("/");
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  let fobeuser = localStorage.getItem("fobeuser");
  let fobeusername = localStorage.getItem("fobeusername");
  let username = localStorage.getItem("Username");
  const [loginUserName, setLoginUserName] = useState(fobeusername == "" ? username == "" ? fobeuser : username : fobeusername);
  const handleSend = () => {
    if (message.trim()) {
      onSend(
        {
          "text": message,
          "sender": loginUserName,
          "images": [],
          "timestamp": new Date(),
          "msgStatus":0
        }); // Call onSend from props
      setMessage("");
      messages.push({
        "text": message,
        "sender": loginUserName,
        "images": [],
        "timestamp": new Date(),
        "msgStatus":0
      })
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };
  const element = [
    <>
      <div style={{ position: 'relative', height: '90vh' }}>
        <Box >
          <Breadcrumbs aria-label="breadcrumb" style={{ padding: '20px' }}>
            <Link underline="hover" to={"/pos"} color="inherit" style={{ color: '#19A73F', textDecoration: 'none', fontSize: '14px' }} onClick={onClose}>
              {/* <HomeIcon sx={{ mr: 0.5, mt: -0.5, width:'15px', height:'15px' }} /> */}
              Point of sale
            </Link>
            <Typography style={{ color: '#000', fontSize: '14px' }}>
              {/* <ManageAccountsIcon sx={{ mr: 0.5, mt: -0.5, fontSize:'15px', width:'15px', height:'15px' }} /> */}
              Mapping Instructions
            </Typography>
          </Breadcrumbs>
        </Box>

        {/* <Typography style={{ fontSize: '18px' }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="chat-title"
          color={'#19a73f'}
        >
          Mapping Instruction
        </Typography> */}
        <Box sx={{ mt: 2, mb: 2 }} className="chat-container">
          <ChatTranscript messages={messages} />
        </Box>

        <div className="chat-input">
          <ReactQuill
            value={message}
            onChange={(e)=>{ e === "<p><br></p>" ? setMessage(""):setMessage(e)}}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
          />
          <IconButton onClick={handleSend}>
            <Send
              fontSize="medium"
              sx={{ color: message.trim() ? "#19a73f" : "#8E8E93" }}
            />
          </IconButton>
        </div>
      </div>


    </>
  ]
  return (
    // <Modal className="Mapping_modal"
    //   open={open}
    //   onClose={onClose}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Header
      handleMobileMenuClose={handleMobileMenuClose}
      anchorEl={anchorEl}
      handleProfileMenuOpen={handleProfileMenuOpen}
      handleMenuClose={handleMenuClose}
      loginUserName={loginUserName}
      handleMenuCloseout={handleMenuCloseout}
      handleMobileMenuOpen={handleMobileMenuOpen}
      mobileMoreAnchorEl={mobileMoreAnchorEl}
      element={element}
    />
    // </Modal>
  );
};

export default InstructionModal;
