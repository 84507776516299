import './App.css';
import { setAxiosDefauls } from './middleware/interceptor';
import { BrowserRouter as Router, Routes, Route, Outlet} from 'react-router-dom';
import { lazy, Suspense } from "react";
import Login from './Login';
import Invoice from './Invoice/Invoice';
import {useState} from 'react';
import Loader from './loader';
import { PrivateRoute } from "./middleware/PrivateRoute";
import UserManagement from './Admin/FobeTeam/UserManagement';
import UserDetails from './Admin/UsersDetails/UserDetails';
import POSTable from './Invoice/PosAccountTable';
import SplitPdf from './Invoice/split';
import PromoCode from './Admin/PromoCode/PromoCode';
import Restaurant from './Admin/AddHQ/AddRestaurant'
import Addhq from './Admin/AddHQ/AddHq';
import UserDetailsInfo from './Admin/UsersDetails/UserDetailsInfo/UserDetailsInfo';
import Billing from './Admin/Billing/Billing';
import EDI_Mapping from './Invoice/EDI Mapping/EDI_Mapping';
import Idel from './Idel';
import Pos from './Pointofsale/Pos';
import InstructionModal from './Pointofsale/InstructionModal';
import MappingInstruction from './Invoice/MappingInstruction/MappingInstruction';
setAxiosDefauls();
function App() {
  const isBackgroundRed = true;
  const[isActive,setIsActive]=useState(true)
  const [validUser, setValidUser] = useState(false);

  const validateUser = (value) => {
    setValidUser(value);
  }
  return (
    <>
     <div className={isBackgroundRed ? 'background-red' : 'background-blue'} />
  <Loader isActive={isActive} />
  
      <Router>
        {validUser && <Idel validateUser={validateUser} />}
        <Routes>
              <Route path="/" element={<Login setIsActive={setIsActive} validateUser={validateUser} />} />
          <Route
            path="/invoice"
            element={<PrivateRoute><Invoice /></PrivateRoute>}
          />
          <Route
            path="/split"
            element={<PrivateRoute><SplitPdf /></PrivateRoute>}
          />
          {/* <Route
            path="/posaccount"
            element={<PrivateRoute><POSTable /></PrivateRoute>}
          /> */}
          <Route
            path="/usermanagement"
            element={<PrivateRoute><UserManagement /></PrivateRoute>}
          />
          <Route
            path="/userdetails"
            element={<PrivateRoute><UserDetails /></PrivateRoute>}
          />
          <Route
            path="userdetailsinfo/:Rest_name/:Account_id/:rest_id"
            element={<PrivateRoute><UserDetailsInfo /></PrivateRoute>}
          />
          <Route
            path="/promocode"
            element={<PrivateRoute><PromoCode /></PrivateRoute>}
          />
          <Route
            path="/hq"
            element={<PrivateRoute><Addhq /></PrivateRoute>}
          />
          <Route
            path="/billing"
            element={<PrivateRoute><Billing /></PrivateRoute>}
          />       
          <Route
            path="/pos"
            element={<PrivateRoute><Pos /></PrivateRoute>}
          />
          <Route
            path="/pos/mappinginstructions"
            element={<PrivateRoute><Pos/></PrivateRoute>}
          />
          <Route path='invoice/mappinginstructions' element={<PrivateRoute><MappingInstruction /></PrivateRoute>} />
		   <Route path="/EDI-Mapping" element={<PrivateRoute><EDI_Mapping /></PrivateRoute>}/>
        <Route path="/hq/group-user/:groupId/:groupName" element={<PrivateRoute><Restaurant/></PrivateRoute>}/>
      </Routes>
    </Router> 
  

   
    </>
  );
}

export default App;
