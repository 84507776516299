import "./Login.css";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { mainAxios } from "./middleware/interceptor";
import { fobeAxios } from "./middleware/interceptor";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LocalStorageService } from "./Services/LocalStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {  Spin } from 'antd';
 import { LoadingOutlined } from '@ant-design/icons';
// import { useHistory } from 'react-router-dom';
const Login = ({setIsActive, validateUser}) => {
  const [login_error_msg, setlogin_error_msg] = useState("");
  const [isLoginError, setisLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const localStorageService = LocalStorageService.getService();
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Accept-Encoding": "gzip, deflate, br",
    Accept: "*/*",
    Connection: "keep-alive",
  };
  const token1 = localStorageService.getAccessToken("auth-token");
  const normalheaders = {
    Authorization: "Bearer " + token1,
    "Content-Type": "application/json",
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .required("Username is required")
        .email("Not valid Email Id")
        .matches(
          "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$",
          "Email Id must be valid"
        ),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (data) => {
      // setIsActive(true);
      setIsLoading(true);
      localStorage.clear();
      console.log("hi", data);
      setisLoginError(false);
      var datas =
        "username=" +
        data.username +
        "-1" +
        "&password=" +
        data.password +
        "&grant_type=password";
      if (data.rememberMe) {
        var user_info = {
          userName: data.username,
          password: data.password,
        };
        //  setWithExpiry(key,user_info);
      }
      return await fobeAxios
        .post("/token", datas, {
          headers: headers,
        })
        .then(
          (res) => {
            setisLoginError(false);
            localStorageService.setToken(res.data.access_token);
            if (res.data.access_token) {
              fobeAxios.get("Api/User/GetUserClaims").then((res) => {
                console.log("claims",res.data);
                  if(res.data.Default_module=="Users")
                  {    
                    navigate("/userdetails");
                  }
                  if(res.data.Default_module=="HQ")
                  {    
                    navigate("/hq");
                  }
                  if(res.data.Default_module=="Promocode")
                  {    
                    navigate("/promocode");
                  }
                  if(res.data.Default_module=="Billing")
                  {    
                    navigate("/billing");
                  }
                  if(res.data.Default_module=="Fobe Team")
                  {
                  navigate("/usermanagement");
                  }
                  if(res.data.Default_module=="Invoice Process")
                  {
                  navigate("/invoice");
                  }
                  if(res.data.Default_module=="Point of sale")
                  {
                  navigate("/posaccount");
                  }
                  if(res.data.Default_module=="EDI Mapping")
                  {
                  navigate("/EDI-Mapping");
                  }
                localStorage.setItem("Menulist",[...res.data.Menu]);
                localStorage.setItem("YetToStart_date",res.data.yettostart_status_startdate);
                localStorage.setItem("yettostart_startdate_fobegolf", res.data.yettostart_startdate_fobegolf);
                localStorage.setItem("fobeuser", res.data.fobeuser);
                localStorage.setItem("fobeusername", res.data.fobeusername);
                localStorage.setItem("Username", res.data.Username);
                localStorage.setItem("Default_module", res.data.Default_module);
                localStorage.setItem("SpecialUser",res.data.Special_user);
                localStorage.setItem("AccountId",res.data.Account_id);
                localStorage.setItem("AccountType",res.data.Account_Type)
                setisLoginError(false);
                setIsLoading(false);
              });
            
              // setIsActive(false);
              validateUser(true);

              console.log("login:", res);
            } else {
              setisLoginError(false);
              // setIsActive(false)
            }
          },
          (error) => {
            let err = error.json();
            if (error.status == 400) {
              if (err.error == 1) {
                setisLoginError(true);
                setIsLoading(false);
                setlogin_error_msg(err.error_description);
                console.log("error", this.login_error_msg);
              } else {
                setIsLoading(false);
                this.openDialog();
              }
            } else {
              setisLoginError(true);
              setIsLoading(false);
              setlogin_error_msg("Connection Failed!");
              console.log(this.login_error_msg);
            }
          }
        )
        .catch((error) => {
          // setIsActive(false);
          setisLoginError(true);
          setIsLoading(false);
          setlogin_error_msg("Provided username and password are incorrect");
          // toast.error("username password incorrect");
          return Promise.reject("invalid");
        });
    },
  });

  const handleClickShowPassword = () => {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    
<>
    
    <div style={{background: "#4EC558", height:"100vh" }}>
      <div class="container-fluid-well">
        <div>
          <img
            src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/header-bannerimage-new.png"
            alt="haederbanner-img"
            class="img img-responsive"
            style={{ width: "100%", height: "115px" }}
          />
          <div class="container">
            <div class="col-md-12">
              <div class="activepage">Log In </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-md-8 col-xs-12 col-lg-5 col-sm-12">
            <div class="card px-5 py-login">
              <div class="form-data">
                {isLoginError && (
                  <div
                    class="col-xs-12 col-md-12 col-lg-12 col-sm-12 Mt"
                    style={{
                      margin:"auto"
                    }}
                  >
                    <div class="alertwarning alert-warning animated bounceIn">
                      <FontAwesomeIcon
                        style={{
                          position: "relative",
                          top: "2px",
                          right: "10px",
                          fontSize: "19px",
                        }}
                        icon={faExclamationCircle}
                      />
                      {/* <i class="fa fa-exclamation-circle" aria-hidden="true" ></i> */}
                      {login_error_msg}
                    </div>
                  </div>
                )}
                <form onSubmit={formik.handleSubmit}>
                  <div className="col-md-12">
                    <TextField
                      label="Username"
                      type="email"
                      variant="standard"
                      autoFocus
                      focused
                      {...formik.getFieldProps("username")}
                      error={
                        formik.touched.username && !!formik.errors.username
                      }
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                    />
                  </div>
                  <div className="col-md-12" style={{ marginTop: "2.5em" }}>
                    <InputLabel htmlFor="filled-adornment-password" focused>
                      Password
                    </InputLabel>
                    <Input
                      focused
                      label="password"
                      variant="standard"
                      id="filled-adornment-password"
                      type={formik.values.showPassword ? "text" : "password"}
                      {...formik.getFieldProps("password")}
                      error={
                        formik.touched.password && !!formik.errors.password
                      }
                      // helperText={formik.touched.password && formik.errors.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {formik.values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password && formik.errors.password && (
                      <FormHelperText error>
                        {formik.errors.password}
                      </FormHelperText>
                    )}
                  </div>
                  <div className="col-md-12">
                    <FormControlLabel
                      style={{ color: "grey", fontSize: "20px" }}
                      label="Remember Me"
                      control={
                        <Checkbox
                          {...formik.getFieldProps("rememberMe")}
                          sx={{
                            color: pink[800],
                            padding: "8px",
                            "&.Mui-checked": {
                              color: pink[600],
                            },
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                          }}
                        />
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <Button
                      className="loginbutton"
                      style={{
                        marginTop: "3em",
                        backgroundColor: pink[600],
                        color: "white",
                        fontSize: "20px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {/* {isLoading ? 'Processing...' : 'Login'} */}

                      {isLoading ? (
                        <>
                          <span>Processing </span>

                           <Spin
                            indicator={
                              <LoadingOutlined style={{ color: "white" }} />
                            }
                          />
                        </>
                      ) : isLoginError ? (
                        "Login"
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
