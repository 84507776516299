import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import "../User.css";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { DatePicker, Switch, Button, Select, Input, Cascader, Drawer, Timeline, Form, Modal, Table, Popconfirm, Skeleton } from 'antd';
import CommonTable from '../CommonTable';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { fobeAxios } from '../../middleware/interceptor';
import dayjs from 'dayjs';
import * as moment from 'moment';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useMemo } from 'react';
import { HiOutlineUserGroup } from "react-icons/hi2";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useFilterContext } from '../../context/ContextProvider';
import { UsergroupAddOutlined } from '@ant-design/icons';
const columnVisibilityModel = {
  "create_Date": true,
  "owner": true,
  "username": true,
  "Primary_mail": false,
  "units": false,
  "phone": false,
  "revenue_Budget": false,
  "amount_nextbilling": true,
  "freq_billing": true,
  "Next_Billing_Date": true,
  "bill_paid_status": true,
  "Bill_type": true,
  "lastlogin": false,
  "admin_sts": true,
  "bill_sts": true,
  "pos_access": true,
  "inv_access": true,
  "asis_access": true,
  "inventory_access": false,
  "custom_logo_access": false,
  "Rest_id": true,
  "password": true,
  "nodify_mail": false,
  "SalesPerson": true,
  "sales": true,
  "Dsr_Sts": true,
  "ckActive": true,
}
const UserDetailsTable = () => {

  const NewArray = [

    {
      "label": 'Active',
      'value': '1'
    },
    {
      "label": 'De-Active',
      'value': '0'
    }
  ];

  const Dsr_sta = [

    {
      label: 'Current',
      value: 1

    }, {

      label: 'Behind',
      value: 2
    },
    {
      label: 'Not Using',
      value: 3
    }
  ]
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  let fobeuser = localStorage.getItem("fobeuser");
  let fobeusername = localStorage.getItem("fobeusername");
  let username = localStorage.getItem("Username");
  const loginUserName = fobeusername == "" ? username == "" ? fobeuser : username : fobeusername;
  const AccessKey = localStorage.getItem("SpecialUser");
  let navigate = useNavigate();
  const [hiddenColumnlist, setHiddenColumnlist] = useState(columnVisibilityModel);
  const [NotifyEmailModalOpen, setNotifyEmailModalOpen] = useState(false);
  const [freqBillingPopUp, setFreqBillingPopUp] = useState(false);
  const [addOnModal, setAddOnModal] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(null);
  const [userlist, setUserlist] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [notificationChkBox, setNotificationChkBox] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [notifyRowId, setNotifyRowId] = useState(null);
  const [UserDetails, setUserDetails] = useState([]);
  const [values, setValues] = useState([]);
  const [FobeTeamList, setFobeTeamList] = useState([]);
  const [salesteamlist, setsalesteamlist] = useState([]);
  const [PasswordForm] = Form.useForm();
  const [NotifyEmailFrom] = Form.useForm();
  const [NotesForm] = Form.useForm();
  const [addRestaurantForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [showdraweropen, setShowdraweropen] = useState(false);
  const [NotesList, setNotesList] = useState([]);
  const [RestDetailsForNotes, setRestDetailsForNotes] = useState({});
  const { TextArea } = Input;
  const [loading, setLoading] = useState(true);
  const { cascaderFilteredValues, setCascaderFilteredValues } = useFilterContext();
  const [cascaderFilter, setcascaderFilter] = useState([]);
  const [filterArray1, setFilterArray] = useState([]);
  const [freqBillingList, setFreqBillingList] = useState([]);
  const [ConsolidatedDrawer, setConsolidatedDrawer] = useState(false);
  const [unMappedRestaurant, setUnMappedRestaurant] = useState([]);
  const [mappedRestaurant, setMappedRestaurant] = useState([]);
  const [unMappedRestaurantlist, setUnMappedRestaurantlist] = useState([]);
  const [primaryLoader, setPrimaryLoader] = useState(true);
  const { confirm } = Modal;
  let temp = [];
  let filterdatas = [];
  let empty = [];
  let filterdata2 = [];
  let budget = [];
  let AccountManager = [];
  let names = '';
  const mappedReataurantColumn = useMemo(() => [
    {
      title: 'Rest Name',
      dataIndex: 'Company',
      key: 'Company',
      align: 'left',
      columnWidth: '300px',
      editable: true,
      render: (_, record) => {
        return record.Rest_id === "" || record?.isprimary ? <span style={{ color: record?.isprimary && '#19A73F', fontWeight: '600' }}>{record.Company}</span> : <span>{record.Company}</span>;
      }
    },
    {
      title: 'Next Billing Amount',
      dataIndex: 'Next_billingamount',
      key: 'Next_billingamount',
      align: 'right',
      editable: true,
      render: (_, record) => {
        return `${record.Rest_id !== "" ? currencyFormatter.format(record.Next_billingamount) : currencyFormatter.format(mappedRestaurant.reduce((total, item) => total + item.Next_billingamount, 0))}`
      }
    },
    {
      title: 'Action',
      dataIndex: 'Rest_id',
      key: 'Rest_id',
      align: 'right',
      editable: true,
      render: (_, record) => {
        return record.Rest_id != "" && !(record?.isprimary) ?
          <DeleteOutlineOutlinedIcon title='Remove Account'
            onClick={() => { showConfirm(record.Rest_id, RestDetailsForNotes) }}
            style={{ color: '#F86764' }} /> : <></>
      }
    },
  ], [mappedRestaurant])
  const showConfirm = (Rest_id, list) => {
    confirm({
      title: (
        <span style={{ color: '#19a73f', fontSize: '1.2em', fontWeight: '600' }}>
          Remove Account
        </span>
      ),
      icon: <></>,
      content: 'Are you sure want to remove this account?',
      footer: (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
          <Button onClick={() => Modal.destroyAll()}>Cancel</Button>
          <Button
            style={{ backgroundColor: '#19a73f', borderColor: '#19a73f', color: '#fff' }}
            onClick={() => {
              RemoveRestaurantInConsolidated(Rest_id, list);
              Modal.destroyAll(); // Close the modal
            }}
          >
            OK
          </Button>
        </div>
      ),
      onCancel() { },
    });
  };
  const showWarning = () => {
    confirm({
      title: (
        <span style={{ color: '#ffa500c7', fontSize: '1.2em', fontWeight: '600' }}>
          Warning
        </span>
      ),
      icon: <></>,
      content: "Please delete all subcategories before disabling the COG subcategories feature",
      footer: (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
          {/* <Button onClick={() => Modal.destroyAll()}>Cancel</Button> */}
          <Button
            style={{ backgroundColor: '#19a73f', borderColor: '#19a73f', color: '#fff' }}
            onClick={() => {
              Modal.destroyAll(); // Close the modal
            }}
          >
            OK
          </Button>
        </div>
      ),
      onCancel() { }
    }
    );
  }
  // const { SHOW_CHILD } = Cascader;
  useEffect(() => { GetUserDetails(); GetFobeTeamList(); GetSalesTeamDropdownList(); }, [])
  const GetUserDetails = async () => {
    await fobeAxios.get("api/Admin/GetUserListsnew_reduce1").then((res) => {
      setLoading(false);
      let temp = res.data.User_Details.map(data => {
        const formattedCreateDate = moment(data.create_Date).format('MM/DD/YYYY');
        const formattedLastLogin = data.lastlogin ? moment(data.lastlogin).format('MM/DD/YYYY hh:mm A') : '-';
        const formattedNextBillingDate = data.Next_Billing_Date ? moment(data.Next_Billing_Date.split('T')[0]).format('MM/DD/YYYY') : '';

        const formattedRevenueBudget = currencyFormatter.format(data.revenue_Budget);
        const revenueBudget = formattedRevenueBudget === '$0.00' ? '-' : formattedRevenueBudget;

        const formattedNextBillingAmount = currencyFormatter.format(data.amount_nextbilling);
        const nextBillingAmount = formattedNextBillingAmount === '$0.00' ? '-' : formattedNextBillingAmount;

        return {
          ...data,
          create_Date: formattedCreateDate,
          lastlogin: formattedLastLogin,
          Next_Billing_Date: formattedNextBillingDate,
          revenue_Budget: revenueBudget,
          amount_nextbilling: nextBillingAmount,
          SalesPerson: data.SalesPerson || '',
          Primary_mail: data.Primary_mail === 'NULL' ? '-' : data.Primary_mail,
          units: data.units == 0 ? '-' : data.units,
        };
      });
      setUserDetails(temp);
      setValues(temp);
      cascaderFilteredValues?.length > 0 && changefilter(cascaderFilteredValues, temp);
    }).catch((err) => {
      console.log(err)
    });
  }
  const GetFobeTeamList = async () => {
    await fobeAxios.get("api/Admin/GetFobeTeamDropdownList").then((res) => {
      let temp = []
      res.data.Data.map((data) => {
        temp = [
          ...temp,
          {
            value: data.ID,
            label: data.Name
          }
        ]
      })
      setFobeTeamList(temp)
    }).catch((err) => {
      console.log(err)
    })
  }
  const GetSalesTeamDropdownList = async () => {
    await fobeAxios.get("api/Admin/GetSalesTeamDropdownList").then((res) => {
      let temp = []
      res.data.Data.map((data) => {
        temp = [
          ...temp,
          {
            value: data.ID,
            label: data.Name
          }
        ]
      })
      setsalesteamlist(temp)
    }).catch((err) => {
      console.log(err)
    })
  }
  const NotifyEmailModal = (val) => {
    setNotifyEmailModalOpen(val);
    if (!val) {
      setInputValue('');
      setCheckBoxError(null);
      setErrorMsg('');
    }
  };
  const onClosepassword = () => {
    setShowdraweropen(false);
  };
  const showDrawerpassword = (res) => {
    PasswordForm.setFieldsValue({
      oldpassword: res.password,
      password: undefined,
      confirm_password: undefined,
      Rest_id: res.Rest_id
    });
    setShowdraweropen(true);
  };
  const handleSubmit = () => {
    PasswordForm.submit();
  };
  const UpdatePassword = async (res) => {
    let reqlist = {
      "details": {
        confirmPassword: res?.confirm_password,
        newPassword: res?.password,
        oldPassword: res?.oldpassword,
        rest_id: res?.Rest_id
      }
    }
    await fobeAxios.post("Api/Admin/UpdatePassword", reqlist).then((data) => {
      if (data.data == 1) {
        setShowdraweropen(false);
        toast.success("New Password Updated Successfully");
        let temp = values.map((data) => {
          return data.Rest_id === reqlist.details.rest_id ?
            { ...data, password: reqlist.details.confirmPassword } : data;
        });
        setValues(temp)
      } else {
        toast.error("Password Failed to Update");
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const UpdateUserStatus = async (val, params) => {
    let req = {
      "details": {
        'status': val ? 1 : 0,
        'rest_id': params.Rest_id
      }
    }
    await fobeAxios.post("Api/Admin/UpdateStatus", req).then((res) => {
      if (res.data.Status == 1) {
        let temp = values.map((data) => {
          return data.Rest_id === params.Rest_id ?
            { ...data, admin_sts: params.admin_sts == 1 ? 0 : 1, bill_sts: val ? params.bill_sts : 0, amount_nextbilling: currencyFormatter.format(res.data.Next_billing_amount) === '$0.00' ? "-" : currencyFormatter.format(res.data.Next_billing_amount), inv_access: val ? params.inv_access : 0, pos_access: val ? params.pos_access : 0, asis_access: val ? params.asis_access : 0 } : data
        });
        setValues(temp);
        let temp1 = UserDetails.map((list) => {
          return list.Rest_id === params.Rest_id ?
            { ...list, admin_sts: params.admin_sts == 1 ? 0 : 1, bill_sts: val ? params.bill_sts : 0, amount_nextbilling: currencyFormatter.format(res.data.Next_billing_amount) === '$0.00' ? "-" : currencyFormatter.format(res.data.Next_billing_amount), inv_access: val ? params.inv_access : 0, pos_access: val ? params.pos_access : 0, asis_access: val ? params.asis_access : 0 } : list
        })
        setUserDetails(temp1);
        // console.log(UserDetails);
        // GetUserDetails();
        toast.success("User Status Updated Successfully");
      } else {
        toast.error("User Status Not Updated");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  const UpdateUserBillingStatus = async (val, params) => {
    let req = {
      "details": {
        'status': val ? 1 : 0,
        'rest_id': params.Rest_id
      }
    }
    await fobeAxios.post("Api/Admin/UpdateBillingStatus", req).then((res) => {
      if (res.data == 1) {
        let temp = values.map((data) => {
          return data.Rest_id === params.Rest_id ?
            { ...data, bill_sts: params.bill_sts == 1 ? 0 : 1 } : data
        });
        setValues(temp);
        let temp1 = UserDetails.map((list) => {
          return list.Rest_id === params.Rest_id ?
            { ...list, bill_sts: params.bill_sts == 1 ? 0 : 1 } : list
        });
        setUserDetails(temp1);
        // GetUserDetails();
        toast.success("User Billing Status Updated Successfully");
      } else {
        toast.error("User Billing Status Not Updated");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  const UpdateAddOnAccess = async (val, params, key, amntaccess, access) => {
    let req;
    let temp;
    if (key === 'Inventory') {
      req = {
        "RestaurantId": params?.Rest_id,
        "key": key,
        "Inventory_access": val
      };
      await fobeAxios.post("api/admin/UpdateAddon_access", req).then((res) => {
        toast.success(res.data.Activation_staus === "De-Active" ? 'Inventory Access Deactivated Successfully' : 'Inventory Access Granted Successfully');
        temp = values.map((data) => {
          return data.Rest_id == req.RestaurantId ? { ...data, inventory_access: data.inventory_access == 1 ? 0 : 1 } : data
        });
        setValues(temp);
        GetUserDetails();
      }).catch((err) => {
        console.log(err)
      })
    } else if (key === 'Custom_logo') {
      req = {
        "RestaurantId": params?.Rest_id,
        "key": key,
        "Custom_logo_access": val
      };
      await fobeAxios.post("api/admin/UpdateAddon_access", req).then((res) => {
        toast.success(res.data.Activation_staus === "De-Active" ? 'Custom Logo Access Deactivated Successfully' : 'Custom Logo Access Granted Successfully');
        temp = values.map((data) => {
          return data.Rest_id == req.RestaurantId ? { ...data, custom_logo_access: data.custom_logo_access == 1 ? 0 : 1 } : data
        });
        setValues(temp);
        GetUserDetails();
      }).catch((err) => {
        console.log(err)
      })
    }

    else if (key == 'Cog_Subcategory') {

      req = {
        'RestaurantId': params?.Rest_id,
        'key': 'cog_subcategory',
        'cog_subcategory_access': val
      }
      console.log(val, 'cog')

      await fobeAxios.post("api/Admin/UpdateAddon_access", req).then((res) => {
        temp = values.map((data) => {
          return data.Rest_id == req.RestaurantId ? { ...data, cog_subcategory_access: data.cog_subcategory_access == 1 ? 0 : 1 } : data
        });
        setValues(temp);
        // GetUserDetails();
        toast.success(res.data.Activation_staus === "De-Active" ? 'Cog Subcategory Access Deactivated Successfully' : 'Cog Subcategory Access Granted Successfully');

      }).catch((err) => {

        console.log(err)

      })

    }
    else {
      let list = { 'key': key, 'list': params, 'value': val };
      if (access == 1) amntaccess == 0 ? setCheckBoxValue(0) : amntaccess == 1 ? setCheckBoxValue(1) : setCheckBoxValue(2);
      setUserlist(list);
      setAddOnModal(true);
    }
  }
  const handleCheckboxChange = (value) => {
    setCheckBoxValue(value);
  };
  const addOnHandler = async (value) => {
    let req;
    if (userlist.key === 'pos') {
      req = {
        "RestaurantId": userlist.list?.Rest_id,
        "key": userlist.key,
        "pos_access": userlist.value,
        "pos_charge": 0.00
      };
    } else if (userlist.key === 'uploadinvoice') {
      req = {
        "RestaurantId": userlist.list?.Rest_id,
        "key": userlist.key,
        "uploadinovice_access": userlist.value,
        "uploadinovice_charge": value == 1 ? 30.00 : 0.00
      };
    } else {
      req = {
        "RestaurantId": userlist.list?.Rest_id,
        "key": userlist.key,
        "fobe_assistant_access": userlist.value,
        "fobe_assistant_charge": value == 2 ? 30.00 : value == 1 ? 20.00 : 0.00
      };
    }

    await fobeAxios.post("api/admin/UpdateAddon_access", req).then((res) => {
      let temp;
      let temp1;
      if (res.data.save_staus == 1) {
        if (req.key == 'pos') {
          (value === 0 || value === 1)
            ? toast.success('POS Access Has Been Updated Successfully')
            : toast.success('POS Access Deactivated Successfully');
          temp = values.map((data) => {
            return data.Rest_id == req.RestaurantId ? { ...data, pos_access: req.pos_access ? 1 : 0, pos_amt_access: value == 1 ? 1 : 0 } : data
          });
        }
        if (req.key == 'uploadinvoice') {
          value === 1
            ? toast.success('Upload Invoice Access With Charge Granted Successfully')
            : value === 0
              ? toast.success('Upload Invoice Access Without Charge Granted Successfully')
              : toast.success('Upload Invoice Access Deactivated Successfully')
          temp = values.map((data) => {
            return data.Rest_id == req.RestaurantId ? { ...data, amount_nextbilling: currencyFormatter.format(res.data.Next_billing_amount) === '$0.00' ? "-" : currencyFormatter.format(res.data.Next_billing_amount), inv_access: req.uploadinovice_access ? 1 : 0, inv_amt_access: value == 1 ? 1 : 0 } : data
          });
        }
        if (req.key == 'fobe_assistant') {
          value === 1 || value === 2
            ? toast.success('Fobe Assistant Access With Charge Granted Successfully')
            : value === 0
              ? toast.success('Fobe Assistant Access Without Charge Granted Successfully')
              : toast.success('Fobe Assistant Access Deactivated Successfully')
          temp = values.map((data) => {
            return data.Rest_id == req.RestaurantId ? { ...data, amount_nextbilling: currencyFormatter.format(res.data.Next_billing_amount) === '$0.00' ? "-" : currencyFormatter.format(res.data.Next_billing_amount), asis_access: req.fobe_assistant_access ? 1 : 0, ass_amt_access: value == 1 ? 1 : value == 2 ? 2 : 0 } : data
          });
        }
        setValues(temp);
        GetUserDetails();
      } else {
        toast.error('Failed to Update');
      }
    }).catch((err) => {
      console.log(err);
    })
    handleClose();
  }
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMsg('');
  };
  const handleInputKeyPress = (event) => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Tab' || event.key === ',') {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        if (validateEmail(trimmedValue)) {
          setEmails([...emails, trimmedValue]);
        }
        setInputValue('');
      }
    } else if (event === 'Submit') {
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        if (validateEmail(trimmedValue)) {
          setEmails([...emails, trimmedValue]);
          setInputValue('');
          return trimmedValue;
        }
        setInputValue('');
      }
    }
  };
  const handleInputKeyDown = (event) => {
    if (event.key === 'Backspace' && inputValue === '') {
      const lastEmail = emails[emails?.length - 1];
      if (lastEmail) {
        setEmails(emails?.slice(0, -1));
      }
    }
  };
  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails?.filter((email) => email !== emailToRemove));
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  const activateNotifyMail = async (validEmails) => {
    const req = {
      "ckActive": notificationChkBox,
      "notify_rest_id": notifyRowId,
      "notifymail": validEmails.length > 0
        ? validEmails.join(', ')
        : ''
    }
    await fobeAxios.post("/Api/Admin/activateNotifyMail", req).then((res) => {
      if (res.data == 1) {
        toast.success("Notification Mail Added Successfully");
        let temp = values.map((data) => {
          return data.Rest_id === req.notify_rest_id ?
            { ...data, nodify_mail: req.notifymail } : data
        });
        setValues(temp);
      } else {
        toast.error("Failed to Updated");
      }
      setNotifyEmailModalOpen(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const notificationSubmitHandler = async () => {
    const validEmails = [...emails];
    const checkEmail = await handleInputKeyPress('Submit');
    if (checkEmail !== undefined) {
      validEmails.push(checkEmail);
    }
    if (validEmails.length === 0) {
      setErrorMsg('Please enter a valid email');
    }
    if (notificationChkBox && validEmails.length > 0) {
      activateNotifyMail(validEmails);
      setCheckBoxError(null);
    } else {
      setCheckBoxError('Please Check the CheckBox');
    }
  }
  const ChangeFobeTeamUser = async (val, params) => {
    let req = {
      "details": {
        "fobeteamsp": val,
        "rest_id": params.Rest_id
      }
    }
    await fobeAxios.post("api/Admin/UpdateFobeSalesPerson", req).then((res) => {
      if (res.data == 1) {
        let temp = values.map((data) => {
          return data.Rest_id == req.details.rest_id ? { ...data, SalesPerson: val } : data
        });
        setValues(temp);
        toast.success("Account Manager Updated Successfully");
      } else {
        toast.error("Not Updated");
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const ChangesalesTeamUser = async (val, params) => {
    let req = {
      "details": {
        "salesperson": val,
        "rest_id": params.Rest_id
      }
    }
    await fobeAxios.post("api/Admin/AddFobeSalesPerson", req).then((res) => {
      if (res.data == 1) {
        let temp = values.map((data) => {
          return data.Rest_id == req.details.rest_id ? { ...data, sales: val } : data
        });
        setValues(temp);
        toast.success("Sales Person Updated Successfully");
      } else {
        toast.error("Not Updated");
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const fetchBillingDate = async (date, row) => {
    const req = {
      "details": {
        "Next_Billing_Date": date,
        "rest_id": row.Rest_id
      }
    }
    await fobeAxios.post('Api/Admin/UpdateNextBillingDate', req).then((res) => {
      if (res.data == 1) {
        if (row.Bill_type === "Primary") {
          let temp = values.map((data) => {
            return data.primary_id === row.Rest_id ? { ...data, Next_Billing_Date: date } :
              data.Rest_id === row.Rest_id ? { ...data, Next_Billing_Date: date } : data;
          });
          setValues(temp);
          let temp1 = values.map((list) => {
            return list.primary_id === row.Rest_id ? { ...list, Next_Billing_Date: date } :
              list.Rest_id === row.Rest_id ? { ...list, Next_Billing_Date: date } : list;
          });
          setUserDetails(temp1);
        } else {
          let temp = values.map((data) => {
            return data.Rest_id === row.Rest_id ? { ...data, Next_Billing_Date: date } : data;
          });
          setValues(temp);
          let temp1 = values.map((list) => {
            return list.Rest_id === row.Rest_id ? { ...list, Next_Billing_Date: date } : list;
          });
          setUserDetails(temp1);
        }
        toast.success('Billing Date Changed successfully')
      } else {
        toast.error('Updated Failed')
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const UpdateBillPaidStatus = async (e, val) => {
    let req = {
      "billing_status": e,
      "rest_id": val.Rest_id
    }

    await fobeAxios.post("api/Admin/savebillingstatus", req).then((res) => {
      if (res.data.status == 1) {
        if (val.Bill_type === "Primary") {
          let temp = values.map((data) => {
            return data.primary_id === req.rest_id ? { ...data, bill_paid_status: req.billing_status } :
              data.Rest_id === req.rest_id ? { ...data, bill_paid_status: req.billing_status } : data
          });
          setValues(temp);
          let temp1 = UserDetails.map((list) => {
            return list.primary_id === req.rest_id ? { ...list, bill_paid_status: req.billing_status } :
              list.Rest_id === req.rest_id ? { ...list, bill_paid_status: req.billing_status } : list
          });
          setUserDetails(temp1);
        } else {
          let temp = values.map((data) => {
            return data.Rest_id === req.rest_id ? { ...data, bill_paid_status: req.billing_status } : data
          });
          setValues(temp);
          let temp1 = UserDetails.map((list) => {
            return list.Rest_id === req.rest_id ? { ...list, bill_paid_status: req.billing_status } : list
          });
          setUserDetails(temp1);
        }

        toast.success("Updated Successfully")
      } else {
        toast.error("Not Updated")
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const GetNotes = async (params) => {
    setOpen(true);
    setRestDetailsForNotes(params);
    NotesForm.resetFields();
    await fobeAxios.get("Api/Admin/getnotes/" + params.Rest_id).then((res) => {
      setNotesList(res.data.LatestNotes);
    }).catch((err) => {
      console.log(err)
    })
  }
  const PostNotes = async (val) => {
    let req = {
      Rest_id: RestDetailsForNotes.Rest_id,
      ...val,
      postedby: loginUserName
    }
    await fobeAxios.post("Api/admin/post_notes", req).then((res) => {
      if (res.data == 1) {
        // toast.success("Notes Updated Successfully!");
        NotesForm.resetFields();
        GetNotes(RestDetailsForNotes)
      } else {
        // toast.error("Notes Not Updated");
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  const changeFrequency = async (value, rest_id, row) => {
    if (row.bill_sts !== 1) {
      setFreqBillingPopUp(true);
      setFreqBillingList(["Please activate the Billing Status before updating the frequency."]);
    } else {
      let req = {
        "rest_id": rest_id,
        "billing_frequency": value
      }

      await fobeAxios.post("api/Admin/UpdateFrequencybilling", req).then((res) => {
        if (res.data.Billing_status == "Pending") {
          setFreqBillingPopUp(true);
          setFreqBillingList(res.data);
          // let temp = values.map((data) => {
          //   return data.Rest_id == rest_id ? { ...data, freq_billing: old_value } : data
          // });
          // setValues(temp);
        } else {
          let temp = values.map((data) => {
            return data.Rest_id == rest_id ? { ...data, freq_billing: value, Next_Billing_Date: res.data.Nextbillingdate_changefreq, amount_nextbilling: currencyFormatter.format(res.data.Nextbillingamount_changefreq) === '$0.00' ? "-" : currencyFormatter.format(res.data.Nextbillingamount_changefreq) } : data
          });
          setValues(temp);
          GetUserDetails();
          toast.success("Billing Frequency Changed Successfully");
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  const UpdateBillType = async (e, val) => {
    let req = {
      "billing_type": e,
      "rest_id": val.original.Rest_id
    }

    await fobeAxios.post("api/Admin/billing_type", req).then((res) => {
      if (res.data.status == 1) {
        let temp = values.map((data) => {
          return data.Rest_id == req.rest_id ? { ...data, Bill_type: req.billing_type } : data
        });
        setValues(temp);
        GetUserDetails();
        toast.success("Updated Successfully")
        if (e === "Primary") {
          val.original.Bill_type = e;
          GetConsolidated(val)
        }
      } else {
        toast.error("Not Updated")
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const GetConsolidated = (row) => {
    GetRestaurantUnderPrimaryAccount(row.original.Bill_type !== 'Primary' ? row.original.primary_id : row.original.Rest_id)
    setConsolidatedDrawer(true);
    setRestDetailsForNotes(row.original);
    GetUnMappedRestaurant(row.original.acc_id)
    addRestaurantForm.resetFields();
  }
  const GetUnMappedRestaurant = async (req) => {
    console.log(req)
    await fobeAxios.get(`api/Admin/Getconsolidated_restaurant?acc_id=${req}`).then((res) => {

      if (res.data.Message == 'Success') {
        setUnMappedRestaurantlist(res.data.sublist)
        setUnMappedRestaurant(res.data.sublist.map(a => {
          return {
            label: a.Company,
            value: `${a.Company}-${a.Rest_id}`
          }
        }))
      } else {
        toast.error("Not Updated")
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const GetRestaurantUnderPrimaryAccount = async (req) => {
    setPrimaryLoader(true);
    setMappedRestaurant([]);
    await fobeAxios.get(`api/Admin/getmappedrestaurant?primary_id=${req}`).then((res) => {
      if (res.data.Message == 'sublist not found') {
        setMappedRestaurant([]);
      } else {
        res.data.Sublist.push({
          "Rest_id": "",
          "Company": "Next Billing Amount",
          "Next_billingamount": ""
        })
        setMappedRestaurant(res.data.Sublist);
        console.log(res.data.Sublist)
      }
      setPrimaryLoader(false);
    }).catch((err) => {
      setPrimaryLoader(false);
      console.log(err)
    })
  }
  const AddConsolidatedRestaurant = async (val, list) => {
    setPrimaryLoader(true);
    let rest_list = val.restaurant_list.map(a => a.split('-')[1]);
    let req = {
      "primary_id": list.Bill_type !== "Primary" ? list.primary_id : list.Rest_id,
      "mapped_Rest": rest_list
    }
    // console.log(unMappedRestaurantlist.filter(a => rest_list.includes(a.Rest_id.toString())),rest_list,unMappedRestaurantlist);
    fobeAxios.post(`api/Admin/mapconsalidatedrestaurant`, req).then(res => {
      console.log(res.data)
      if (res.data.Status == 1) {
        GetUserDetails();
        addRestaurantForm.resetFields();
        GetRestaurantUnderPrimaryAccount(list.Bill_type !== "Primary" ? list.primary_id : list.Rest_id);
        GetUnMappedRestaurant(list.acc_id);
        toast.success('Restaurants added successfully');
        // if(mappedRestaurant.length < 0){
        // }else{
        //   let temp = mappedRestaurant.splice(mappedRestaurant.length-1,1)
        // }

        // setMappedRestaurant((prevItems) => [...prevItems, ...unMappedRestaurantlist.filter(a => rest_list.includes(a.Rest_id.toString())),...temp]);
      } else {
        setPrimaryLoader(false);
        toast.error("Not Added")
      }
    })
  }
  const RemoveRestaurantInConsolidated = async (req, list) => {
    setPrimaryLoader(true);
    fobeAxios.post(`api/Admin/Removeconsolidatedrestaurant?rest_id=${req}`).then(res => {
      console.log(res.data)
      if (res.data.Status == 1) {
        GetRestaurantUnderPrimaryAccount(list.Bill_type !== "Primary" ? list.primary_id : list.Rest_id);
        GetUnMappedRestaurant(list.acc_id);
        GetUserDetails();
        toast.success('Restaurants removed successfully');
        // if(mappedRestaurant.length < 3 ){
        //   setMappedRestaurant((prevItems) => prevItems.filter(a => a.Rest_id != req))
        // }else{
        //   setMappedRestaurant([])
        // }
      } else {
        setPrimaryLoader(false);
        toast.error("Failed to remove")
      }
    })
  }

  const handleClose = () => {
    setAddOnModal(false);
    setCheckBoxValue(null);
  };
  const options = [
    {
      label: 'Account Type',
      value: 'Account Type',
      children: [
        {
          label: 'Fobesoft',
          value: '1',
        },
        {
          label: 'Fobegolf',
          value: '5',
        },
        {
          label: 'Sysco',
          value: '3',
        },
      ]
    },
    {
      label: 'Status',
      value: 'Status',
      children: [
        {
          label: 'Active',
          value: '1',
        },
        {
          label: 'De Active',
          value: '0',
        },
        {
          label: 'Pending',
          value: '2',
        },
      ]
    },
    {
      label: 'POS',
      value: 'Pos',
      children: [
        {
          label: 'Toast',
          value: '1',
        },
        // {
        //   label: 'Square',
        //   value: '2',
        // },
        // {
        //   label: 'Clover',
        //   value: '3',
        // },
        // {
        //   label: 'SpotOn',
        //   value: '4',
        // },
        // {
        //   label: '7Shifts',
        //   value: '5',
        // },
        {
          label: 'QuickBooks',
          value: '6',
        },
      ]
    },
    {
      label: 'Units',
      value: 'Units',
      children:
        new Array(10).fill(null).map((_, index) => ({
          label: `${index + 1}`,
          value: index + 1,
        })),


    },
    {
      label: 'Account Manager',
      value: 'AccountManager',
      children: [
        ...FobeTeamList
      ]
    },
  ];
  const changefilter = (val, userDetails, selectedOptions,) => {
    console.log(val);
    setCascaderFilteredValues(prevState => prevState !== val ? val : prevState);
    let temp = [];
    let filterdatas = [];
    let empty = [];
    let filterdata2 = [];

    let AccountManager = [];

    let names = '';

    for (let i = 0; i < val.length; i++) {
      let labelname = val[i][0];
      if (val[i][0] == 'Account Type' && val[i][1]) {

        if (i > 0 && labelname != val[i - 1][0]) {
          names = val[i - 1][0]
        }
        const filterdata =
          names == 'AccountManager'
            ? AccountManager.filter((data => data.acc_id == val[i][1]))
            : names == 'Status'
              ? filterdatas.filter((data => data.acc_id == val[i][1]))
              : names == 'Pos'
                ? empty.filter((data => data.acc_id == val[i][1]))
                : names == 'Units'
                  ? filterdata2.filter((data => data.acc_id == val[i][1]))
                  : userDetails.filter((data => data.acc_id == val[i][1]));

        if (filterdata.length > 0) {
          temp = [...temp, ...filterdata];
          setValues(temp)
        }
        else {
          setValues([])
        }

      }

      if (val[i][0] == 'Account Type' && val[i][1] == undefined) {

        if (i > 0 && labelname != val[i - 1][0]) {
          names = val[i - 1][0]

        }
        const allaccount =
          names == 'AccountManager'
            ? AccountManager.filter((data => data.acc_id == 1 || data.acc_id == 3 || data.acc_id == 5))
            : names == 'Status'
              ? filterdatas.filter((data => data.acc_id == 1 || data.acc_id == 3 || data.acc_id == 5))
              : names == 'Pos'
                ? empty.filter((data => data.acc_id == 1 || data.acc_id == 3 || data.acc_id == 5))
                : names == 'Units'
                  ? filterdata2.filter((data => data.acc_id == 1 || data.acc_id == 3 || data.acc_id == 5))
                  : userDetails.filter((data => data.acc_id == 1 || data.acc_id == 3 || data.acc_id == 5));
        temp = [...temp, ...allaccount];
        setValues(temp)
      }


      if (val[i][0] == 'Status' && val[i][1]) {

        if (i > 0 && labelname != val[i - 1][0]) {
          names = val[i - 1][0]

          let num = val[i - 1][1]
        }
        const filterdata1 =
          names == 'AccountManager'
            ? AccountManager.filter((data => data.admin_sts == val[i][1]))
            : names == 'Account Type'
              ? temp.filter((data => data.admin_sts == val[i][1]))
              : names == 'Pos'
                ? empty.filter((data => data.admin_sts == val[i][1]))
                : names == 'Units'
                  ? filterdata2.filter((data => data.admin_sts == val[i][1]))
                  : userDetails.filter((data => data.admin_sts == val[i][1]));

        if (filterdata1.length > 0) {
          filterdatas = [...filterdatas, ...filterdata1];

          setValues(filterdatas)

        }
        else {
          setValues(filterdatas)

        }
      }


      if (val[i][0] == 'Status' && val[i][1] == undefined) {

        if (i > 0 && labelname != val[i - 1][0]) {
          names = val[i - 1][0]

          let num = val[i - 1][1]


        }
        const status =
          names == 'AccountManager'
            ? AccountManager.filter((data => data.admin_sts >= 0))
            : names == 'Account Type'
              ? temp.filter((data => data.admin_sts >= 0))
              : names == 'Pos'
                ? empty.filter((data => data.admin_sts >= 0))
                : names == 'Units'
                  ? filterdata2.filter((data => data.admin_sts >= 0))
                  : userDetails.filter((data => data.admin_sts >= 0));
        filterdatas = [...filterdatas, ...status];
        setValues(filterdatas)

      }


      if (val[i][0] == 'Pos' && val[i][1]) {


        if (i > 0 && labelname != val[i - 1][0]) {

          names = val[i - 1][0]


        }
        const filterdata3 =
          names == 'AccountManager'
            ? AccountManager.filter((data => data.POS == val[i][1]))
            : names == 'Status'
              ? filterdatas.filter((data => data.POS == val[i][1]))
              : names == 'Account Type'
                ? temp.filter((data => data.POS == val[i][1]))
                : names == 'Units'
                  ? filterdata2.filter((data => data.POS == val[i][1]))
                  : userDetails.filter((data => data.POS == val[i][1]));


        if (filterdata3.length > 0) {
          empty = [...empty, ...filterdata3];

          setValues(empty)
        }
        else {
          setValues([])
        }
      }
      if (val[i][0] == 'Pos' && val[i][1] == undefined) {

        if (i > 0 && labelname != val[i - 1][0]) {

          names = val[i - 1][0]


        }
        const allposacc =
          names == 'AccountManager'
            ? AccountManager.filter((data => data.POS > 0))
            : names == 'Status'
              ? filterdatas.filter((data => data.POS > 0))
              : names == 'Account Type'
                ? temp.filter((data => data.POS > 0))
                : names == 'Units'
                  ? filterdata2.filter((data => data.POS > 0))
                  : userDetails.filter((data => data.POS == val[i][1]));
        empty = [...empty, ...allposacc];
        setValues(empty)

      }

      if (val[i][0] == 'Units' && val[i][1]) {

        if (i > 0 && labelname != val[i - 1][0]) {

          names = val[i - 1][0]

        }
        const filterdata =
          names == 'Status'
            ? filterdatas.filter((data => data.units == val[i][1]))
            : names == 'Account Type'
              ? temp.filter((data => data.units == val[i][1]))
              : names == 'Pos'
                ? empty.filter((data => data.units == val[i][1]))
                : names == 'AccountManager'
                  ? AccountManager.filter((data => data.units == val[i][1]))
                  : userDetails.filter((data => data.units == val[i][1]));

        filterdata2 = [...filterdata2, ...filterdata];

        setValues(filterdata2)

      }

      if (val[i][0] == 'Units' && val[i][1] == undefined) {
        if (i > 0 && labelname != val[i - 1][0]) {

          names = val[i - 1][0]


        }
        //names=='Status' ? filterdatas.filter((data => data.units == val[i][1])): names=='Account Type'? temp.filter((data => data.units == val[i][1])): names=='Pos' ?empty.filter((data=> data.units==val[i][1])):names=='AccountManager'?AccountManager.filter((data=> data.units==val[i][1])): UserDetails.filter((data => data.units == val[i][1]));
        const allunits =
          names == 'Status'
            ? filterdatas.filter((data => data.units > 0))
            : names == 'Account Type'
              ? temp.filter((data => data.units > 0))
              : names == 'Pos'
                ? empty.filter((data => data.units > 0))
                : names == 'AccountManager'
                  ? AccountManager.filter((data => data.units > 0))
                  : userDetails.filter((data => data.units > 0));
        filterdata2 = [...filterdata2, ...allunits];
        setValues(filterdata2)

      }

      if (val[i][0] == 'AccountManager') {


        if (i > 0 && labelname != val[i - 1][0]) {

          names = val[i - 1][0]


        }

        const filterdata =
          names == 'Status'
            ? filterdatas.filter((data => data.SalesPerson == val[i][1]))
            : names == 'Account Type'
              ? temp.filter((data => data.SalesPerson == val[i][1]))
              : names == 'Units'
                ? filterdata2.filter((data => data.SalesPerson == val[i][1]))
                : names == 'Pos'
                  ? empty.filter((data => data.Bossusercount == val[i][1]))
                  : userDetails.filter((data => data.SalesPerson == val[i][1]));
        if (filterdata.length > 0) {
          AccountManager = [...AccountManager, ...filterdata];
          setValues(AccountManager)
        }
        else {
          setValues([])
        }


      }


      if (val[i][0] == 'AccountManager' && val[i][1] == undefined) {

        if (i > 0 && labelname != val[i - 1][0]) {

          names = val[i - 1][0]
        }
        const allaccmanager =
          names == 'Account Type'
            ? temp.filter((data => data.SalesPerson > 0))
            : names == 'Status'
              ? filterdatas.filter((data => data.SalesPerson > 0))
              : names == 'Pos'
                ? empty.filter((data => data.SalesPerson > 0))
                : names == 'Units'
                  ? filterdata2.filter((data => data.SalesPerson > 0))
                  : userDetails.filter((data => data.SalesPerson > 0));
        filterdata2 = [...filterdata2, ...allaccmanager];
        setValues(filterdata2)

      }
    }


    if (val.length == 0) {

      setValues(UserDetails)

    }
  }
  const ChooseValue = (res) => {
    let val = FobeTeamList.filter((data) => {
      return (data.value === res);
    })
    return val
  }

  const ChooseValuesales = (res) => {
    let val = salesteamlist.filter((data) => {
      return (data.value === res);
    })
    return val
  }
  const GotoProfile = (res) => {
    navigate("/userdetailsinfo/" + res.Company + "/" + res.acc_id + "/" + res.Rest_id);
  }
  const customStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    padding: '5px',
  };

  const textAreaStyle = {
    flex: 1,
    border: 'none',
    boxShadow: 'none',
    resize: 'none',
  };

  const buttonStyle = {
    marginLeft: '8px',
    borderColor: '#d9d9d900',
  };
  const columns = useMemo(() => [
    {
      accessorKey: "Company",
      header: 'Company',
      editable: false,
      enableColumnPinning: true,
      enableColumnResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row }) => {
        return <span style={{ color: "#19A73F" }}>{row.original.Company}</span>
      }
    },
    {
      accessorKey: "create_Date",
      header: 'Date',
      editable: false,
      enableColumnPinning: true,
      enableColumnResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      sortUndefined: false,
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = new Date(rowA.original[columnId]);
        const valueB = new Date(rowB.original[columnId]);

        // Compare dates
        return valueA - valueB;
      }
    },
    {
      accessorKey: "owner",
      header: 'Owner Name',
      editable: false,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "username",
      header: 'Username',
      editable: false,
      size: 250,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "Primary_mail",
      header: 'Primary Email',
      editable: false,
      size: 230,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "units",
      header: 'Units',
      editable: false,
      size: 150,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "phone",
      header: 'Contact Number',
      editable: false,
      size: 200,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },

    },
    {
      accessorKey: "revenue_Budget",
      header: 'Revenue Budget',
      editable: false,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
    },
    {
      accessorKey: "amount_nextbilling",
      header: 'Next Billing Amount',
      editable: false,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
    },
    {
      accessorKey: "freq_billing",
      header: 'Billing Frequency',
      editable: false,
      enableColumnPinning: true,
      enableResizing: true,
      filterVariant: 'select',
      filterSelectOptions: [
        { label: 'All', value: '' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
        { label: 'Annually', value: 'Annually' }
      ],
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <Select
            disabled={AccessKey === '999' || row.original.Bill_type === "Consolidated"}
            style={{
              width: 150,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            defaultValue={row.original.freq_billing}
            value={row.original.freq_billing}
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            options={[
              { label: 'All', value: '', hidden: true },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Quarterly', value: 'Quarterly' },
              { label: 'Annually', value: 'Annually' }
            ]}
            onChange={(value) => { changeFrequency(value, row.original.Rest_id, row.original) }}
          />
        )
      }
    },
    {
      accessorKey: "Next_Billing_Date",
      header: 'Next Billing Date',
      editable: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      sortUndefined: false,
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.original[columnId] ? new Date(rowA.original[columnId]) : new Date(0);
        const dateB = rowB.original[columnId] ? new Date(rowB.original[columnId]) : new Date(0);

        return dateA - dateB;
      },
      Cell: ({ row }) => {
        return (
          <DatePicker
            disabled={AccessKey === '999' || row.original.Bill_type === "Consolidated"}
            onChange={(date, dateString) => fetchBillingDate(dateString, row.original)}
            defaultValue={row.original.Next_Billing_Date ? dayjs(row.original.Next_Billing_Date) : ''}
            value={row.original.Next_Billing_Date ? dayjs(row.original.Next_Billing_Date) : ''}
            format={'MM/DD/YYYY'}
            allowClear={false}
          />
        )
      }
    },
    {
      accessorKey: "bill_paid_status",
      header: 'Billing Status',
      filterVariant: 'select',
      filterSelectOptions: [
        {
          "label": 'All',
          'value': ''
        },
        {
          "label": 'Current',
          'value': 'Current'
        },
        {
          "label": 'Delinquent',
          'value': 'Delinquent'
        },
        {
          "label": 'Test',
          'value': 'Test'
        },
        {
          "label": 'Paused',
          'value': 'Paused'
        },
        {
          'label': 'Canceled',
          'value': 'Canceled'
        }
      ],
      enableResizing: true,
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <Select
          disabled={AccessKey === '999' || row.original.acc_id === 5 || row.original.Bill_type === "Consolidated"}
          style={{
            width: 150,
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          defaultValue={row.original.bill_paid_status}
          value={row.original.bill_paid_status}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          options={[
            { label: 'Select', value: '', hidden: true },
            { label: 'Current', value: 'Current' },
            { label: 'Delinquent', value: 'Delinquent' },
            { label: 'Test', value: 'Test' },
            { label: 'Paused', value: 'Paused' },
            { label: 'Canceled', value: 'Canceled' }
          ]}
          onChange={(e) => { UpdateBillPaidStatus(e, row.original) }}
        />
      ),
    },

    {
      accessorKey: "Bill_type",
      header: 'Billing Type',
      editable: false,
      size: 150,
      enableColumnPinning: true,
      enableResizing: true,
      filterSelectOptions: [
        { label: 'All', value: '' },
        { label: 'Individual', value: 'Individual' },
        { label: 'Primary', value: 'Primary' },
        { label: 'Consolidated', value: 'Consolidated' },
      ],
      filterVariant: 'select',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className='assistflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>

          <Select
            disabled={AccessKey === '999' || row.original.acc_id == 5}
            style={{
              width: 150,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            defaultValue={row.original.Bill_type}
            value={row.original.Bill_type}
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            options={[
              { label: 'Select', value: '', hidden: true },
              { label: 'Individual', value: 'Individual', disabled: row.original.Bill_type == 'Consolidated' },
              { label: 'Primary', value: 'Primary', disabled: row.original.Bill_type == 'Consolidated' },
              { label: 'Consolidated', value: 'Consolidated', disabled: row.original.Bill_type != 'Consolidated' },
            ]}
            onChange={(e) => { UpdateBillType(e, row) }}
          />
          <>
            {(row.original.Bill_type === 'Primary' || row.original.Bill_type === 'Consolidated') ?
              (<HiOutlineUserGroup style={{ color: '#19A73F', fontSize: '18px' }} onClick={() => { GetConsolidated(row) }} />)
              : (<div style={{ width: '18px' }}>
                <p style={{ textAlign: 'center' }}></p>
              </div>)}
          </>
        </div>
      ),
    },
    {
      accessorKey: "lastlogin",
      header: 'Last Login',
      editable: false,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "admin_sts",
      header: 'Status',
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      enableResizing: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <Switch
            size="small"
            disabled={AccessKey === '999'}
            checked={row.original.admin_sts === 1} // Use 'row.original.Status' to access the value
            onChange={(e) => UpdateUserStatus(e, row.original)}
          />
        )
      }
    },
    {
      accessorKey: "bill_sts",
      header: 'Billing',
      editable: false,
      size: 150,
      enableColumnPinning: true,
      enableResizing: true,
      filterSelectOptions: NewArray.map(row => {
        return row
      }),

      filterVariant: 'select',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <Switch
          size="small"
          disabled={row.original.admin_sts === 0 || AccessKey === '999'} // Use row.original to access the data
          checked={row.original.bill_sts === 1}
          onChange={(e) => UpdateUserBillingStatus(e, row.original)}
        />
      ),

    },
    {
      accessorKey: "pos_access",
      header: 'POS',
      editable: false,
      size: 150,
      enableColumnPinning: true,
      enableResizing: true,
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className='posflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            size="small"
            disabled={row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999'}
            checked={row.original.pos_access === 1}
            onChange={(e) => UpdateAddOnAccess(e, row.original, 'pos', row.original.pos_amt_access, row.original.pos_access)}
          />
          {row.original.pos_access === 1 ? (
            <img onClick={(e) => AccessKey === '999' ? '' : row.original.admin_sts === 0 || row.original.bill_sts === 0 ? '' : UpdateAddOnAccess(true, row.original, 'pos', row.original.pos_amt_access, row.original.pos_access)}
              src={row.original.pos_amt_access === 0
                ? "https://app.fobesoft.com/assets/img/toller1.png"
                : "https://app.fobesoft.com/assets/img/toller.png"}
              style={{ width: '24px', cursor: row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999' ? 'not-allowed' : 'pointer' }}
            />
          ) : (
            <div style={{ width: '24px' }}>
              <p style={{ textAlign: 'center' }}>-</p>
            </div>
          )}
        </div>
      ),

    },
    {
      accessorKey: "inv_access",
      header: 'Invoice',
      enableResizing: true,
      size: 150,
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },

      Cell: ({ row }) => (
        <div className='invoiceflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            size="small"
            disabled={row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999'}
            checked={row.original.inv_access === 1}
            onChange={(e) => UpdateAddOnAccess(e, row.original, 'uploadinvoice', row.original.inv_amt_access, row.original.inv_access)}
          />
          {row.original.inv_access === 1 ? (
            <img onClick={(e) => AccessKey === '999' ? '' : row.original.admin_sts === 0 || row.original.bill_sts === 0 ? '' : UpdateAddOnAccess(true, row.original, 'uploadinvoice', row.original.inv_amt_access, row.original.inv_access)}
              src={row.original.inv_amt_access === 0
                ? "https://app.fobesoft.com/assets/img/toller1.png"
                : "https://app.fobesoft.com/assets/img/toller.png"}
              style={{ width: '24px', cursor: row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999' ? 'not-allowed' : 'pointer' }}
            />
          ) : (
            <div style={{ width: '24px' }}>
              <p style={{ textAlign: 'center' }}>-</p>
            </div>
          )}
        </div>
      ),
    },
    {
      accessorKey: "asis_access",
      header: 'Fobe Assistant',
      enableResizing: true,
      size: 150,
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className='assistflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            size="small"
            disabled={row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999'}
            checked={row.original.asis_access === 1}
            onChange={(e) => UpdateAddOnAccess(e, row.original, 'fobe_assistant', row.original.ass_amt_access, row.original.asis_access)}
          />
          {row.original.asis_access === 1 ? (
            <img onClick={(e) => AccessKey === '999' ? '' : row.original.admin_sts === 0 || row.original.bill_sts === 0 ? '' : UpdateAddOnAccess(true, row.original, 'fobe_assistant', row.original.ass_amt_access, row.original.asis_access)}
              src={row.original.ass_amt_access === 0
                ? "https://app.fobesoft.com/assets/img/toller1.png"
                : "https://app.fobesoft.com/assets/img/toller.png"}
              style={{ width: '24px', cursor: row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999' ? 'not-allowed' : 'pointer' }}
            />
          ) : (
            <div style={{ width: '24px' }}>
              <p style={{ textAlign: 'center' }}>-</p>
            </div>
          )}
        </div>
      ),

    },
    {
      accessorKey: "inventory_access",
      header: 'Inventory',
      enableResizing: true,
      size: 150,
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className='assistflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            size="small"
            disabled={row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999'}
            checked={row.original.inventory_access === 1}
            onChange={(e) => UpdateAddOnAccess(e, row.original, 'Inventory', 0, row.original.inventory_access)}
          />
        </div>
      ),

    },

    {
      accessorKey: "cog_subcategory_access",
      header: 'COG Subcategory',
      enableResizing: true,
      size: 150,
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className='assistflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            size="small"
            disabled={row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999'}
            checked={row.original.cog_subcategory_access === 1}
            onChange={(e) => {
              console.log("cog_subcategory_count", row.original.cog_subcategory_count);
              (e === false && row.original.cog_subcategory_count > 0) ? showWarning():
                UpdateAddOnAccess(e, row.original, 'Cog_Subcategory', 0, row.original.cog_subcategory_access)
            }}
          />
        </div>
      ),

    },

    {
      accessorKey: "custom_logo_access",
      header: 'Custom Logo',
      enableResizing: true,
      size: 150,
      filterSelectOptions: NewArray.map(row => {
        return row

      }),

      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div className='assistflex' style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            size="small"
            disabled={row.original.admin_sts === 0 || row.original.bill_sts === 0 || AccessKey === '999'}
            checked={row.original.custom_logo_access === 1}
            onChange={(e) => UpdateAddOnAccess(e, row.original, 'Custom_logo', 0, row.original.custom_logo_access)}
          />
        </div>
      ),

    },
    {
      accessorKey: "Rest_id",
      header: 'Details',
      editable: false,
      size: 150,
      enableSorting: false,
      enableColumnFilter: false,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          <span onClick={() => AccessKey === '999' ? '' : GotoProfile(row.original)} style={{ cursor: AccessKey === '999' ? 'not-allowed' : 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0C21.7322 0 28 6.2678 28 14C28 21.7322 21.7322 28 14 28C6.2678 28 0 21.7322 0 14C0 6.2678 6.2678 0 14 0ZM20.3 11.9C19.743 11.9 19.2089 12.1212 18.8151 12.5151C18.4212 12.9089 18.2 13.443 18.2 14C18.2 14.557 18.4212 15.0911 18.8151 15.4849C19.2089 15.8788 19.743 16.1 20.3 16.1C20.857 16.1 21.3911 15.8788 21.7849 15.4849C22.1787 15.0911 22.4 14.557 22.4 14C22.4 13.443 22.1787 12.9089 21.7849 12.5151C21.3911 12.1212 20.857 11.9 20.3 11.9ZM14 11.9C13.443 11.9 12.9089 12.1212 12.5151 12.5151C12.1212 12.9089 11.9 13.443 11.9 14C11.9 14.557 12.1212 15.0911 12.5151 15.4849C12.9089 15.8788 13.443 16.1 14 16.1C14.557 16.1 15.0911 15.8788 15.4849 15.4849C15.8788 15.0911 16.1 14.557 16.1 14C16.1 13.443 15.8788 12.9089 15.4849 12.5151C15.0911 12.1212 14.557 11.9 14 11.9ZM7.7 11.9C7.14305 11.9 6.6089 12.1212 6.21508 12.5151C5.82125 12.9089 5.6 13.443 5.6 14C5.6 14.557 5.82125 15.0911 6.21508 15.4849C6.6089 15.8788 7.14305 16.1 7.7 16.1C8.25695 16.1 8.7911 15.8788 9.18492 15.4849C9.57875 15.0911 9.8 14.557 9.8 14C9.8 13.443 9.57875 12.9089 9.18492 12.5151C8.7911 12.1212 8.25695 11.9 7.7 11.9Z" fill="#4ECB71" />
            </svg>
          </span>
        )
      }

    },
    {
      accessorKey: "password",
      header: 'Password',
      editable: false,
      size: 150,
      enableSorting: false,
      enableColumnPinning: true,
      enableColumnFilter: false,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          // <Button disabled={AccessKey === '999'}  type="primary" style={{ backgroundColor: '#faa732' }} onClick={() => showDrawerpassword(row.original)}>Click</Button>

          <span onClick={() => showDrawerpassword(row.original)} style={{ cursor: 'pointer' }}>
            <svg xmlns="
http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 28 28" fill="none">
              <circle cx="14" cy="14" r="14" fill="#4ECB71" />
              <path d="M16.6923 10.2143C16.6923 11.0668 16.3519 11.8843 15.746 12.4871C15.1402 13.0899 14.3184 13.4286 13.4615 13.4286C12.6047 13.4286 11.7829 13.0899 11.177 12.4871C10.5712 11.8843 10.2308 11.0668 10.2308 10.2143C10.2308 9.3618 10.5712 8.54424 11.177 7.94144C11.7829 7.33865 12.6047 7 13.4615 7C14.3184 7 15.1402 7.33865 15.746 7.94144C16.3519 8.54424 16.6923 9.3618 16.6923 10.2143ZM7 18.7857C7 19.8571 8.07692 19.8571 8.07692 19.8571H13.4615V18.7857C13.4613 18.7141 13.4649 18.6426 13.4723 18.5714C13.4217 17.8297 13.5445 17.0865 13.8311 16.4C14.1177 15.7134 14.5604 15.1023 15.1243 14.6146C14.6214 14.5407 14.0689 14.5 13.4615 14.5C8.07692 14.5 7 17.7143 7 18.7857ZM14.5385 18.7857C14.5385 18.5016 14.6519 18.229 14.8539 18.0281C15.0558 17.8272 15.3298 17.7143 15.6154 17.7143V16.6429C15.6154 16.0745 15.8423 15.5295 16.2462 15.1276C16.6502 14.7258 17.198 14.5 17.7692 14.5C18.3405 14.5 18.8883 14.7258 19.2922 15.1276C19.6962 15.5295 19.9231 16.0745 19.9231 16.6429V17.7143C20.2087 17.7143 20.4826 17.8272 20.6846 18.0281C20.8865 18.229 21 18.5016 21 18.7857V20.9286C21 21.2127 20.8865 21.4853 20.6846 21.6862C20.4826 21.8871 20.2087 22 19.9231 22H15.6154C15.3298 22 15.0558 21.8871 14.8539 21.6862C14.6519 21.4853 14.5385 21.2127 14.5385 20.9286V18.7857ZM17.7692 15.5714C17.4836 15.5714 17.2097 15.6843 17.0077 15.8852C16.8058 16.0862 16.6923 16.3587 16.6923 16.6429V17.7143H18.8462V16.6429C18.8462 16.3587 18.7327 16.0862 18.5307 15.8852C18.3288 15.6843 18.0548 15.5714 17.7692 15.5714Z" fill="white" />
            </svg>
          </span>

        )
      }
    },
    {
      accessorKey: "nodify_mail",
      header: 'Email',
      editable: false,
      size: 150,
      enableSorting: false,
      enableColumnFilter: false,
      enableColumnPinning: true,
      enableResizing: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          // <Button
          //   disabled={AccessKey === '999'} 
          //   type="primary"
          //   style={{ backgroundColor: '#1191a2' }}
          //   onClick={() =>  {
          //     NotifyEmailModal(true);
          //     setNotifyRowId(row.original.Rest_id);
          //     const notifyMails = row.original.nodify_mail
          //       ? row.original.nodify_mail?.split(', ')
          //       : [];
          //     setEmails(notifyMails);
          //     setNotificationChkBox(row.original.ckActive)
          //   }}
          // >
          //   View
          // </Button>
          <span onClick={() => {
            AccessKey === '999' ? NotifyEmailModal(false) :
              NotifyEmailModal(true);
            setNotifyRowId(row.original.Rest_id);
            const notifyMails = row.original.nodify_mail
              ? row.original.nodify_mail?.split(', ')
              : [];
            setEmails(notifyMails);
            setNotificationChkBox(row.original.ckActive)
          }}
            style={{ cursor: AccessKey === '999' ? 'not-allowed' : 'pointer' }}>
            <svg xmlns="
http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 28 28" fill="none">
              <circle cx="14" cy="14" r="14" fill="#4ECB71" />
              <path d="M8 8C7.54688 7.99997 7.10719 8.1538 6.75292 8.43631C6.39866 8.71881 6.15082 9.11324 6.05 9.555L14 14.414L21.95 9.555C21.8492 9.11324 21.6013 8.71881 21.2471 8.43631C20.8928 8.1538 20.4531 7.99997 20 8H8ZM6 17.8V10.698L11.803 14.244L6 17.8ZM12.761 14.83L6.191 18.856C6.35318 19.1985 6.60932 19.4878 6.92959 19.6904C7.24985 19.8929 7.62107 20.0003 8 20H14.256C14.086 19.5182 13.9994 19.0109 14 18.5C13.9993 17.8431 14.1428 17.194 14.4203 16.5986C14.6978 16.0032 15.1025 15.4759 15.606 15.054L15.239 14.829L14 15.586L12.761 14.83ZM22 15.671V10.697L16.197 14.243L16.535 14.451C17.1473 14.1534 17.8192 13.9992 18.5 14C19.914 14 21.175 14.652 22 15.671Z" fill="white" />
              <path d="M21.834 18.244C21.834 19.412 21.257 20.269 20.247 20.269C19.744 20.269 19.245 20.041 19.127 19.621H19.084C18.966 20.037 18.541 20.264 18.069 20.264C17.299 20.264 16.81 19.722 16.81 18.83V18.301C16.81 17.457 17.291 16.901 18.07 16.901C18.655 16.901 18.94 17.234 19.023 17.531H19.053V16.963H19.958V19.153C19.958 19.425 20.138 19.573 20.369 19.573C20.684 19.573 21.008 19.158 21.008 18.183V18.065C21.008 16.788 20.058 15.739 18.524 15.739H18.484C16.902 15.739 15.844 16.806 15.844 18.463V18.62C15.844 20.487 17.081 21.274 18.414 21.274H18.459C18.966 21.274 19.394 21.204 19.639 21.094V21.825C19.42 21.925 18.996 22 18.402 22H18.358C16.438 22 15 20.82 15 18.646V18.432C15 16.36 16.421 15 18.485 15H18.52C20.64 15 21.834 16.43 21.834 18.034V18.244ZM17.794 18.454V18.681C17.794 19.267 18.021 19.481 18.375 19.481C18.685 19.481 18.939 19.311 18.939 18.738V18.371C18.939 17.855 18.664 17.663 18.367 17.663C18.021 17.663 17.794 17.908 17.794 18.454Z" fill="white" />
            </svg>
          </span>
        )
      }
    },
    {
      accessorKey: "SalesPerson",
      header: 'Account Manager',
      enableResizing: true,
      enableSorting: false,
      filterSelectOptions: FobeTeamList.map(row => ({
        label: row.label.toUpperCase(),
        value: row.value,
      })),
      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <Select
          disabled={AccessKey === '999'}
          style={{
            width: 150,
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          defaultValue={ChooseValue(row.original.SalesPerson)[0]?.label}
          value={ChooseValue(row.original.SalesPerson)[0]?.label}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          options={FobeTeamList}
          onChange={(e) => { ChangeFobeTeamUser(e, row.original) }}
        />
      ),

    },
    {
      accessorKey: "sales",
      header: 'Sales Person',
      enableResizing: true,
      enableSorting: false,
      filterSelectOptions: salesteamlist.map(row => ({
        label: row.label.toUpperCase(),
        value: row.value,
      })),
      filterVariant: 'select',
      editable: false,
      enableColumnPinning: true,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <Select
          disabled={AccessKey === '999'}
          style={{
            width: 150,
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          defaultValue={ChooseValuesales(row.original.sales)[0]?.value}
          value={ChooseValuesales(row.original.sales)[0]?.value}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          options={salesteamlist}
          onChange={(e) => { ChangesalesTeamUser(e, row.original) }}
        />
      ),

    },
    {
      accessorKey: "Dsr_Sts",
      header: 'DSR Status',
      editable: false,
      size: 150,
      enableColumnPinning: true,
      enableResizing: true,
      filterSelectOptions: Dsr_sta.map(data => {
        return data
      }),
      filterVariant: 'select',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: (row) => (
        // console.log(row)
        <Chip
          label={
            row.row.original.Dsr_Sts === 3 ? "Not Using" :
              row.row.original.Dsr_Sts === 2 ? "Behind" : "Current"
          }
          style={{
            fontSize: '12px',
            height: '25px',
            paddingTop: '2px',
            borderRadius: '50px',
            background: row.row.original.Dsr_Sts === 3 ? "#fa5959" :
              row.row.original.Dsr_Sts === 2 ? "#ff8533" :
                "#53c653",
            color: '#fff'
          }}
        />
      ),

    },
    {
      accessorKey: "ckActive",
      header: 'Notes',
      editable: false,
      size: 150,
      enableSorting: false,
      enableColumnPinning: true,
      enableResizing: true,
      enableColumnFilter: false,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        return (
          // <Button  disabled={AccessKey === '999'}  type="primary" style={{ backgroundColor: '#faa732' }} onClick={() => GetNotes(row.original)}>View</Button>
          <span onClick={() => AccessKey === '999' ? '' : GetNotes(row.original)} style={{ cursor: AccessKey === '999' ? 'not-allowed' : 'pointer' }}>
            <svg xmlns="
http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 28 28" fill="none">
              <circle cx="14" cy="14" r="14" fill="#4ECB71" />
              <path d="M19.1309 7L21.4072 9.27625L19.6719 11.0123L17.3957 8.73602L19.1309 7ZM10.7938 17.6134H13.07L18.599 12.0844L16.3228 9.80814L10.7938 15.3372V17.6134Z" fill="white" />
              <path d="M19.14 19.8897H10.9136C10.8939 19.8897 10.8734 19.8973 10.8537 19.8973C10.8287 19.8973 10.8036 19.8904 10.7778 19.8897H8.5175V9.26715H13.7127L15.2302 7.74965H8.5175C7.6806 7.74965 7 8.42949 7 9.26715V19.8897C7 20.7273 7.6806 21.4072 8.5175 21.4072H19.14C19.5425 21.4072 19.9285 21.2473 20.2131 20.9627C20.4976 20.6781 20.6575 20.2921 20.6575 19.8897V13.3128L19.14 14.8303V19.8897Z" fill="white" />
            </svg>
          </span>
        )
      }

    },
  ],
    [values])

  const test = [
    <>
      <div>
        <div className='cascader'>
          <Cascader
            style={{
              height: '38px'
            }}
            options={options}
            onChange={(e) => changefilter(e, UserDetails)}
            defaultValue={cascaderFilteredValues}
            multiple
            maxTagCount="responsive"
            placeholder="Filter By" // This won't work as expected, see the next code snippet

          />
        </div>

      </div>
    </>
  ]
  return (
    <>
      <Box>

        <div className='useertable'>

          <CommonTable
            rows={values}
            columns={columns}
            table={"userdetails"}
            FobeTeamList={FobeTeamList}
            salesteamlist={salesteamlist}
            HiddenColumnlist={hiddenColumnlist}
            isLoading={loading}
            headFilter={test}
          />
        </div>

        <Drawer
          title={<><span style={{ color: 'rgb(0 0 0 / 88%)' }}>{RestDetailsForNotes.Company}</span></>}
          width={500}
          onClose={() => { setOpen(false); }}
          open={open}
          closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}
        >
          <Form form={NotesForm} onFinish={PostNotes}>
            <div style={customStyle} className='typemessage'>

              <Form.Item style={{ width: '100%' }} rules={[{ required: true, message: "" }]} name="notes">
                <TextArea
                  style={textAreaStyle}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  placeholder="Type your message here"
                />
              </Form.Item>
              <Button htmlType='submit' icon={<SendIcon style={{ color: '#32c65a' }} />} style={buttonStyle} />
            </div>
          </Form>
          <div className='timelinedrawer'>
            <Timeline
              items={[...NotesList.map((data) => {
                return {
                  color: '#19A73F',
                  children: (
                    <>
                      <p className='usernamede'>{data.postedby}</p>

                      <TextArea readOnly className='insidearea' style={{ marginTop: '10px' }}
                        editable={false}
                        autoSize='none'
                        value={data.notes}
                      />
                      <p className='datetimeline'>{data.date}</p>
                    </>
                  ),
                }
              })]}
            />
          </div>
        </Drawer>


        <Drawer
          title="Change Password"
          width={500}
          onClose={onClosepassword}
          open={showdraweropen}
          closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}

          footer={  // Add the footer section
            <div
              className='drawer-btn' style={{ display: 'flex', gap: '10px' }} >
              <Button type="primary" disabled={AccessKey === '999'} onClick={handleSubmit} style={{ backgroundColor: '#19A73F', }}>Save</Button>
              <Button
                style={{
                  backgroundColor: 'rgb(239 239 239)',
                  color: '#000',
                  borderRadius: '3px',
                  border: '0.5px solid #b2b2b2',
                }}
                onClick={onClosepassword}
              >
                Cancel
              </Button>
            </div>
          }
        >
          <Form
            layout="vertical"
            form={PasswordForm}
            onFinish={UpdatePassword}
          >

            <Form.Item label="Old Password" name="oldpassword">
              <Input placeholder="Old Password" disabled style={{ height: '40px' }} />
            </Form.Item>
            <Form.Item
              label="New Password" name="password" rules={[{ required: true, message: "Please enter a password." }, ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!getFieldValue('password').includes('&')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The password cannot contain the "&" symbol.'));
                },
              }),]}>
              <Input.Password disabled={AccessKey === '999'} placeholder="New Password" style={{ height: '40px' }} />
            </Form.Item>
            <Form.Item
              label="Confirm Password" name="confirm_password" dependencies={['password']} rules={[{ required: true, message: "Please confirm your password." }, ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),]}>
              <Input.Password disabled={AccessKey === '999'} placeholder="Confirm Password" style={{ height: '40px' }} />
            </Form.Item>

            <Form.Item name="Rest_id"></Form.Item>
          </Form>
        </Drawer>


        <Modal
          title="Email Notifications"
          open={NotifyEmailModalOpen}
          onCancel={() => { NotifyEmailModal(false) }}
          footer={null}
        >
          <Form
            form={NotifyEmailFrom}
            onFinish={notificationSubmitHandler}
          >
            <Form.Item
              rules={[
                {
                  message: 'Email is required',
                  required: true,
                },
                {
                  type: 'email',
                  message: "Invalid Email"
                }
              ]}
            >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Notification Email"
                type="email"
                fullWidth
                variant="standard"
                className='textField'
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                onKeyDown={handleInputKeyDown}
                InputProps={{
                  startAdornment: (
                    <div className='email-token-outer'>
                      {emails?.map((email) => (
                        <div key={email} className='email-token'>
                          {email}
                          <CloseIcon
                            onClick={() => handleRemoveEmail(email)}
                            className="remove-icon"
                          />
                        </div>
                      ))}
                    </div>
                  ),
                }}
              />
              {errorMsg.length > 0 &&
                <div className='row'>
                  <p className='errorMsg'>
                    {errorMsg}
                  </p>
                </div>
              }
              <div className='row'>
                <p className='commapop'>
                  *Use Comma or Space to add multiple emails
                </p>
              </div>
            </Form.Item>
            <div style={{ textAlign: 'center' }}>
              {emails?.length > 0 &&
                <div className='notify-chkbox'>
                  <Checkbox
                    id='notification'
                    checked={notificationChkBox}
                    value={notificationChkBox}
                    onChange={(e) => {
                      setNotificationChkBox(e.target.checked);
                      setCheckBoxError(e.target.checked ? '' : null);
                    }}
                  >
                    Enable Send Notification Email
                  </Checkbox>
                  {checkBoxError !== null &&
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'left',
                        // marginTop: 5
                      }}
                    >{checkBoxError}</div>
                  }
                </div>

              }
              <div className='subbuton'>
                <Form.Item style={{ marginBottom: '0px' }}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#19A73F',
                      borderRadius: '3px',
                      height: '37px',
                      marginTop: '20px',

                    }}
                    onClick={notificationSubmitHandler}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          title="ADD ON"
          open={addOnModal}
          onCancel={handleClose}
          footer={[
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className='addOnSaveBtn'
                style={{
                  backgroundColor: '#19A73F',
                  color: '#fff',
                  borderRadius: '3px',
                  border: 'none'
                }}
                onClick={() => addOnHandler(userlist?.value ? checkBoxValue : 3)}
                disabled={userlist?.value ? checkBoxValue !== null ? false : true : false}
              >
                {userlist?.value ? [<>Confirm</>] : [<>Confirm</>]}

              </Button>
              <Button
                style={{
                  backgroundColor: 'rgb(239 239 239)',
                  color: '#000',
                  borderRadius: '3px',
                  border: '0.5px solid #b2b2b2',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          ]}
        >
          <div className='backgree' style={{ background: userlist?.value ? 'rgb(114 189 114)' : '#f86a67' }}>
            <p>{userlist?.key == 'pos' ? 'POS Integration' : userlist?.key == 'uploadinvoice' ? 'Upload Invoice' : 'Fobesoft Assistant'}</p>
          </div>
          <div className='groupcheck'>

            {userlist?.value ?
              [
                <>
                  {userlist?.key == "fobe_assistant" ?
                    <>
                      <Checkbox
                        id='1'
                        value={1}
                        checked={checkBoxValue === 1}
                        onChange={() => handleCheckboxChange(1)}
                        style={{ color: '#1D2B36' }}
                      >
                        {/* Access With Charge */}
                        $20
                      </Checkbox>
                      <Checkbox
                        id='2'
                        value={2}
                        checked={checkBoxValue === 2}
                        onChange={() => handleCheckboxChange(2)}
                        style={{ color: '#1D2B36' }}
                      >
                        {/* Access With Charge */}
                        $30
                      </Checkbox>
                    </>
                    :
                    <>
                      <Checkbox
                        id='1'
                        value={1}
                        checked={checkBoxValue === 1}
                        onChange={() => handleCheckboxChange(1)}
                        style={{ color: '#1D2B36' }}
                      >
                        Access With Charge
                      </Checkbox>
                    </>
                  }
                  <Checkbox
                    id='0'
                    value={0}
                    checked={checkBoxValue === 0}
                    onChange={() => handleCheckboxChange(0)}
                    style={{ color: '#1D2B36' }}
                  >
                    Only Access
                  </Checkbox>
                </>
              ] :
              [
                <>
                  <div>
                    <span>
                      <h6>Do you want to Deactivate the {userlist?.key == 'pos' ? 'POS Integration' : userlist?.key == 'uploadinvoice' ? 'Upload Invoice' : 'Fobesoft Assistant'}?</h6>
                    </span>
                  </div>
                </>
              ]
            }
          </div>
        </Modal>
        <Modal
          title="Frequency Billing"
          open={freqBillingPopUp}
          maskClosable={false}
          onCancel={() => {
            setFreqBillingPopUp(false)

          }}
          footer={[
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className='addOnSaveBtn'
                style={{
                  backgroundColor: '#19A73F',
                  color: '#fff',
                  borderRadius: '3px',
                }}
                onClick={() => { setFreqBillingPopUp(false) }}
              >
                <>Confirm</>
              </Button>
              {/* <Button
                style={{
                  backgroundColor: 'rgb(239 239 239)',
                  color: '#000',
                  borderRadius: '3px',
                  border: '0.5px solid #b2b2b2',
                }}
                onClick={() => { setFreqBillingPopUp(false) }}
              >
                Cancel
              </Button> */}
            </div>
          ]}
        >
          <>
            {freqBillingList[0] === "Please activate the Billing Status before updating the frequency." ?
              <p style={{ textAlign: "center" }}>
                Please activate the Billing Status before updating the frequency.
              </p>
              :
              <p style={{ textAlign: "center" }}>
                We noticed that this account has missed the {freqBillingList.Monthpending < 12 ? `last ${freqBillingList.Monthpending} Months` : freqBillingList.Monthpending == 1 ? `Past Month` : `Past Year`} of payments,
                totaling {freqBillingList.Total_amount_to_Pay}. If you wish to continue this process, please complete the payment for this account.
              </p>

            }

          </>
        </Modal>

        <Drawer
          title={<><span style={{ color: '#19A73F' }}>{RestDetailsForNotes.primary_id === "" ? RestDetailsForNotes.Company : RestDetailsForNotes.primary_company}</span></>}
          width={500}
          onClose={() => { setConsolidatedDrawer(false); }}
          open={ConsolidatedDrawer}
          closeIcon={<KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />}
        >
          {/* <div> */}

          <Form onFinish={(values) => { AddConsolidatedRestaurant(values, RestDetailsForNotes) }} form={addRestaurantForm}>
            <div className='row'>
              <div className='col-md-10'>
                <Form.Item
                  name="restaurant_list"
                  // label="Add Restaurant"
                  rules={[
                    {
                      message: "Please select the restaurant",
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder='Select Restaurant'
                    options={unMappedRestaurant}
                    size='large'
                  // style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className='col-md-2'>
                {/* <Button htmlType='submit' icon={<> */}
                {/* <UsergroupAddOutlined htmlType='submit' style={{ color: '#19A73F', fontSize: '25px', padding: '7px 0px 0px 0px', cursor: 'pointer' }} /></>}>Add</Button> */}
                <UsergroupAddOutlined title='Add Account' onClick={() => { !primaryLoader && addRestaurantForm.submit() }} style={{ color: '#19A73F', fontSize: '25px', padding: '7px 0px 0px 0px', cursor: !primaryLoader ? 'pointer' : 'not-allowed' }} />
              </div>
            </div>
          </Form>
          {!primaryLoader ? <>
            <div className='newroledraw' style={{ marginTop: '40px' }}>
              <Table
                dataSource={mappedRestaurant}
                columns={mappedReataurantColumn}
                showHeader={false}
                pagination={false}
              />
            </div>
          </>
            : <Skeleton />
          }
        </Drawer>
      </Box>

    </>
  );
}


export default UserDetailsTable;
