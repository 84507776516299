import React, { useEffect, useRef, useState } from "react";
import { Table, Space, Input, Spin } from "antd";

const DataTable1 = ({
  columns,
  st,
  tableData1,
  handleTableChange,
  sorter,
  filters,
  loading,
  tdata,
  st1,
}) => {

  const showTotal = (total, range) => {
    return (
      <div style={{ textAlign: "right" }}>
        Showing {range[0]} to {range[1]} of {total} entries
      </div>
    );
  };


  const tableRef = useRef(null);

  useEffect(() => {
    const handleTableScroll = () => {
      // Handle the scroll event here
      st(true);
    };

    const tableBody = tableRef.current?.querySelector(".ant-table-body");
    tableBody?.addEventListener("scroll", handleTableScroll);

    return () => {
      tableBody?.removeEventListener("scroll", handleTableScroll);
    };
  }, []);

  return (
    <>
      <Spin tip="Loading Please wait..." spinning={loading}>
        <Table
          columns={columns}
          dataSource={tableData1}
          loading={false}
          showSorterTooltip={false}
          pagination={false}
          onChange={handleTableChange}
          scroll={{ x: 1000, y: 400 }}
          onScroll={() => {}}
          ref={tableRef}
        />
      </Spin>
    </>
  );
};

export default DataTable1;
