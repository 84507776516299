import React, { createContext, useState, useContext } from "react";

export const CascaderContext = createContext();
;

const CascaderContextProvider = ({children}) => {
  const [cascaderFilteredValues, setCascaderFilteredValues] = useState([]);

  return (
    <CascaderContext.Provider
      value={{
        cascaderFilteredValues,
        setCascaderFilteredValues
      }}
    >
      {children}
    </CascaderContext.Provider>
  )
}

const useFilterContext = () => {
  return useContext(CascaderContext);
}


export {
  CascaderContextProvider,
  useFilterContext
};
