import React, { useEffect, useState } from 'react';
import Header from "../../Header/header";
import "../User.css";
import { Button, } from 'antd';
import { fobeAxios } from '../../middleware/interceptor';
import ExcelJS from 'exceljs';
import { VerticalAlignCenter } from '@mui/icons-material';

function Billing() {
    const [billing, setbilling] = useState({})

    const currentDate = new Date();
    const prev1=new Date()
    const prev2=new Date()
    const prev3=new Date()
    let current_count
    let greater_one
    let lesser_one
    // Get the month (zero-based, so we add 1 to get the actual month)
    //const month = currentDate.getMonth() ;
   // console.log(month, 'month')

    const previous_month_1 = []
    const previous_month_2 = []
    const previous_month_3 = []

     prev1.setMonth(currentDate.getMonth()-1)

     prev2.setMonth(currentDate.getMonth()-2)

     prev3.setMonth(currentDate.getMonth()-3)
    
    const Months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    };
    useEffect(() => {
        async function api() {
            await fobeAxios('api/Admin/GetBillingCounts').then((res) => {
                setbilling(res.data)
                console.log(res.data)
            })
        }
        api();
    }
        , [])

    if (Object.keys(billing)?.length > 0) {

        previous_month_1.push(billing[Months[prev1.getMonth() + 1]][0])
        previous_month_2.push(billing[Months[prev2.getMonth() + 1]][0])
        previous_month_3.push(billing[Months[prev3.getMonth() + 1]][0])
        
        current_count = previous_month_1[0].current_count + previous_month_2[0].current_count + previous_month_3[0].current_count + billing['Currentweek'][0].current_count
        greater_one = previous_month_1[0].greater_one + previous_month_2[0].greater_one + previous_month_3[0].greater_one + billing['Currentweek'][0].greater_one
        lesser_one = previous_month_1[0].lesser_one + previous_month_2[0].lesser_one + previous_month_3[0].lesser_one + billing['Currentweek'][0].lesser_one

        console.log(previous_month_1[0], previous_month_2[0], previous_month_3[0], current_count, greater_one, lesser_one)
    }

    const Downloadexcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Sheet`);
        worksheet.mergeCells('B1:F1')
        worksheet.mergeCells('A1:A7')
        worksheet.getCell('A1').font = { bold: true };
        // worksheet.getCell('A6').alignment ={ wrapText: true };
        worksheet.getCell('A1:A7').alignment = { textRotation: 90, horizontal: 'center',vertical:'center' };

        worksheet.getCell('B1').value = 'Payment Status'
        worksheet.getCell('B2').value = 'Active Status'
        worksheet.getCell('A2').value = 'Last Login'
        worksheet.getCell('C2').value = 'Current'
        worksheet.getCell('D2').value = '< 1 Month Behind'
        worksheet.getCell('E2').value = ' >1 Month Behind'
        worksheet.getCell('F2').value = 'Total'
        worksheet.columns.forEach((column) => {
            column.width = 25;
            // column.eachCell((cell) => {
            //   cell.alignment = { horizontal: 'center' };
            // });
        });
        const head = ['B1','B2', 'C2', 'D2', 'E2', 'F2', 'B3', 'C3', 'D3', 'E3', 'F3', 'B4', 'C4', 'D4', 'E4', 'F4', 'B5', 'C5', 'D5', 'E5', 'F5', 'B6', 'C6', 'D6', 'E6', 'F6', 'B7', 'C7', 'D7', 'E7', 'F7']
        head.forEach((cell) => {
            worksheet.getCell(cell).alignment = { horizontal: 'center' };
        });
        worksheet.getCell(`B3`).value = 'This Month';
        worksheet.getCell(`C3`).value = billing['Currentweek'][0].current_count;
        worksheet.getCell(`D3`).value = billing['Currentweek'][0].greater_one;
        worksheet.getCell(`E3`).value = billing['Currentweek'][0].lesser_one;
        worksheet.getCell(`F3`).value = billing['Currentweek'][0].current_count + billing['Currentweek'][0].greater_one + billing['Currentweek'][0].lesser_one;

        previous_month_1.forEach((item, index) => {
            worksheet.getCell(`B4`).value = Months[prev1.getMonth() + 1]
            worksheet.getCell(`C4`).value = item.current_count;
            worksheet.getCell(`D4`).value = item.lesser_one;
            worksheet.getCell(`E4`).value = item.greater_one;
            worksheet.getCell(`F4`).value = item.current_count + item.greater_one + item.lesser_one;


        })
        previous_month_2.forEach((item, index) => {
            worksheet.getCell(`B5`).value = Months[prev2.getMonth() + 1]
            worksheet.getCell(`C5`).value = item.current_count;
            worksheet.getCell(`D5`).value = item.lesser_one;
            worksheet.getCell(`E5`).value = item.greater_one;
            worksheet.getCell(`F5`).value = item.current_count + item.greater_one + item.lesser_one;


        })

        previous_month_3.forEach((item, index) => {
            worksheet.getCell(`B6`).value = Months[prev3.getMonth() + 1]
            worksheet.getCell(`C6`).value = item.current_count;
            worksheet.getCell(`D6`).value = item.lesser_one;
            worksheet.getCell(`E6`).value = item.greater_one;
            worksheet.getCell(`F6`).value = item.current_count + item.greater_one + item.lesser_one;


        })
        worksheet.getCell(`B7`).value = 'Total'
        worksheet.getCell(`C7`).value = current_count;
        worksheet.getCell(`D7`).value = lesser_one;
        worksheet.getCell(`E7`).value = greater_one;
        worksheet.getCell(`F7`).value = current_count + greater_one + lesser_one;
        const heading = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1', 'V1',]
        heading.forEach((head) => {
            worksheet.getCell(head).font = { bold: true };
        })
        // Generate a buffer from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
        // Create a blob from the buffer
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // Download the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Billing.xlsx';
        link.click();
        // Cleanup
        URL.revokeObjectURL(url);
    }

    console.log(previous_month_1)
    const bill = [

        <>{(console.log('hi', previous_month_1))}

            <div className='content-container'>
                <div className='containerford' style={{ marginTop: '5px' }}>
                    <div className='expobut'>
                        <Button type="primary" onClick={Downloadexcel}>Export</Button>
                    </div>



                    <table class="custom-table">
                        <thead>
                            <tr>
                                <th colSpan={2} style={{ border: 'none' }}></th>
                                <th class="backcolor" colSpan={4}>Payment Status</th>
                            </tr>
                            <tr>
                                <th className='largetext' colSpan={2}>Active Status</th>
                                <th>Current</th>
                                <th>&lt; 1 Month Behind</th>
                                <th>&gt; 1 Month Behind</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(billing).length > 0 &&
                                <tr>

                                    <td class="backcolor verticaltext" rowSpan={5}>Last Login</td>
                                    <td className='leftclass'>This Month</td>
                                    {(billing['Currentweek']) && (<td className='greenclass'>{billing['Currentweek'][0].current_count}</td>)}
                                    {(billing['Currentweek']) && (<td className='yellowclass'>{billing['Currentweek'][0].lesser_one}</td>)}
                                    {(billing['Currentweek']) && (<td className='redclass'>{billing['Currentweek'][0].greater_one}</td>)}
                                    {(billing['Currentweek']) && (<td className='boldclass'>{billing['Currentweek'][0].current_count + billing['Currentweek'][0].greater_one + billing['Currentweek'][0].lesser_one}</td>)}
                                </tr>
                            }

                            {(previous_month_1?.length > 0) && previous_month_1?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='leftclass'>{Months[prev1.getMonth() + 1]}</td>
                                        <td className='greenclass'>{item.current_count}</td>
                                        <td className='yellowclass'>{item.lesser_one}</td>
                                        <td className='redclass'>{item.greater_one}</td>
                                        <td className='boldclass'>{item.current_count + item.lesser_one + item.greater_one}</td>
                                    </tr>
                                )
                            })
                            }

                            {previous_month_2?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='leftclass'>{Months[prev2.getMonth() + 1]}</td>
                                        <td className='greenclass'>{item.current_count}</td>
                                        <td className='yellowclass'>{item.lesser_one}</td>
                                        <td className='redclass'>{item.greater_one}</td>
                                        <td className='boldclass'>{item.current_count + item.lesser_one + item.greater_one}</td>
                                    </tr>
                                )
                            })
                            }


                            {previous_month_3?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='leftclass'>{Months[prev3.getMonth() + 1]}</td>
                                        <td className='greenclass'>{item.current_count}</td>
                                        <td className='yellowclass'>{item.lesser_one}</td>
                                        <td className='redclass'>{item.greater_one}</td>
                                        <td className='boldclass'>{item.current_count + item.lesser_one + item.greater_one}</td>
                                    </tr>
                                )
                            })
                            }

                            <tr>
                                <td className='leftclass'>Total</td>
                                <td className='boldclass'>{current_count}</td>
                                <td className='boldclass'>{lesser_one}</td>
                                <td className='boldclass'>{greater_one}</td>
                                <td className='boldclass'>{current_count + lesser_one + greater_one}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </>
    ];
    console.log(billing, 'bill')
    return (
        <div>
            <Header element={bill} />

        </div>

    );
}


export default Billing;
