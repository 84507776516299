import React, { useEffect } from "react";
import { useRef } from "react";
import { Table, Space, Input, Spin } from "antd";

const DataTable = ({
  columns,
  tableData,
  handleTableChange,
  sorter,
  filters,
  setLoading,
  loading,
  currentIndex,
  pageSize,
  totalPages,
  scrollenable,
  scrolleb,
}) => {
  const tableRef = useRef(null);

  useEffect(() => {
    if (!loading) {
      const handleTableScroll = () => {
        console.log("scrollisEnable1d");

        scrollenable(true);
      };

      const tableBody = tableRef.current?.querySelector(".ant-table-body");
      tableBody?.addEventListener("scroll", handleTableScroll);

      return () => {
        tableBody?.removeEventListener("scroll", handleTableScroll);
      };
    }
  }, [scrolleb, loading]);

  const showTotal = (total, range) => {
    return (
      <div style={{ textAlign: "right" }}>
        Showing {range[0]} to {range[1]} of {total} entries
      </div>
    );
  };
  useEffect(() => {
    console.log("curentindex", currentIndex);
  }, [currentIndex]);
  return (
    <>
      {loading ? (
        <Spin tip="Loading Please wait...">
          <Table
            columns={columns}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onMouseEnter: (event) => {scrollenable(false)}, // mouse enter row
            //   };
            // }}
            dataSource={tableData}
            showSorterTooltip={false}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "500"],
              showTotal: showTotal,
              current: currentIndex + 1,
              total: tableData.length,
            }}
            onChange={handleTableChange}
            filters={filters}
            sorter={sorter}
            showsizeChanger={true}
            scroll={{ x: 1000, y: 400 }}
            loading={false}
          />
        </Spin>
      ) : (
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onMouseEnter: (event) => {scrollenable(false)}, // mouse enter row
          //   };
          // }}
          columns={columns}
          dataSource={tableData}
          showSorterTooltip={false}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "500"],
            showTotal: showTotal,
            current: currentIndex + 1,
            total: tableData.length,
          }}
          onChange={handleTableChange}
          filters={filters}
          sorter={sorter}
          showsizeChanger={true}
          scroll={{ x: 1000, y: 400 }}
          loading={false}
          ref={tableRef}
        />
      )}
    </>
  );
};

export default DataTable;
