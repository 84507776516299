import { Timeline, Empty } from "antd";
// import { ArrowRightOutlined } from '@ant-design/icons';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import './ChangeLog.css';
import { useEffect, useState } from "react";
import { getDateLabel } from "../../utils/dateUtils";
import moment from "moment";
const ChangeLog = ({ data }) => {
    const [timelineList, setTimelineList] = useState([])
    let lastDateLabel = null;
    useEffect(() => {
        let temp = data?.reverse()?.map((a) => {
            const dateLabel = getDateLabel(a.Datechanged);
            const showDateLabel = dateLabel !== lastDateLabel;
            lastDateLabel = dateLabel;
            return {
                dot: (<>
                    <div className="timeLineLable">
                        {a?.Changedby.charAt(0)}
                    </div>
                </>),
                color: 'green',
                children: (<>
                    <div>
                        <div className="logDetails-head">
                            <p>{a?.Changedby}</p>
                            {showDateLabel && <p>{dateLabel}</p>}
                            <p>{moment(a?.Datechanged).format('h:mm A')}</p>
                        </div>
                        <div className="log-box">
                            <p>{a?.ChangeEvent}</p>
                            {a?.ChangeEvent != "Lineitem Reviewed" &&
                                <div>
                                    <span>{a?.Oldcatname}</span>
                                    <EastSharpIcon style={{ fontSize: '14px' }} />
                                    <span>{a?.Newcatname}</span>
                                </div>
                            }
                            <p>{a?.Description}</p>
                        </div>
                    </div>
                </>)
            }
        });
        setTimelineList(temp)
    }, [data])
    return (
        <>
            <div>
                {data?.length > 0 ?
                    (<Timeline
                        items={timelineList}
                    />)
                    :
                    (
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10em' }}>
                            <Empty />
                        </div>
                    )
                }
            </div>
        </>
    );

};
export default ChangeLog;