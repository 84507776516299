import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link, useLocation } from 'react-router-dom';
import { FaGift } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { LogoutOutlined } from '@ant-design/icons';
import { BlockOutlined } from '@ant-design/icons';
import {Tooltip} from 'antd';
import { fobeAxios } from '../middleware/interceptor';

const Header=({ handleMenuCloseout,element })=>{
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  let fobeuser = localStorage.getItem("fobeuser");
  let fobeusername = localStorage.getItem("fobeusername");
  let username = localStorage.getItem("Username");
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccountId"));
  const [loginUserName,setLoginUserName]=useState(fobeusername=="" ? username=="" ? fobeuser : username : fobeusername);
  const drawerWidth = 200;
  const [menuList, setMenuList] = useState(localStorage?.getItem("Menulist"));
  const [FilterMenu,setFilterMenu] = useState([]);
  const CommonMenuList = [
    { text: 'Users', icon: <FaUsersCog />,  link: '/userdetails' },
    { text: 'HQ', icon: <FaUsers />,  link: '/hq' },
    { text: 'Promocode', icon: <FaGift />,  link: '/promocode' },
    { text: 'Billing', icon: <FaMoneyCheckAlt />,  link: '/billing' },
    { text: 'Fobe Team', icon: <FaUserFriends />,  link: '/usermanagement' },
    { text: 'Invoice Process', icon: <FaFileInvoice />, link: '/invoice' },
    { text: 'Point of Sale', icon: <FaCreditCard />, link: '/pos' },
    { text: 'EDI Mapping', icon: <BlockOutlined />, link: '/EDI-Mapping'}
   ]
  // useEffect(()=>{getMenuList();},[]);

  // const getMenuList =async() =>{
  //   await fobeAxios.get("Api/User/GetUserClaims").then((res)=>{
  //     console.log(res.data.Menu)
  //     setMenuList(res?.data?.Menu)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  useEffect(()=>{
  let temp = CommonMenuList.filter((data)=>(menuList?.toLowerCase().split(',')).includes(data.text.toLowerCase()));
  setFilterMenu(temp);
  console.log(temp,menuList);
  },[])
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const linkStyles = {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  };
  const location = useLocation();
  const isListItemActive = (path) => {
    return location.pathname.includes(path);
  };
  const removeStorages=()=>{
    sessionStorage.removeItem('mrt_globalFilter_table_1')
    sessionStorage.removeItem('mrt_showGlobalFilter_table_1')
    sessionStorage.removeItem('mrt_columnFilters_table_1')
    sessionStorage.removeItem('mrt_showColumnFilters_table_1')
    localStorage.clear()
  }
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{
          display: 'flex',
          transition: 'width 0.2s',
          width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
        }}>
        <Toolbar sx={{ display: 'flex',}}>
         
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{    border: '1px solid #19A73F', borderRadius: '3px', padding: '3px', marginRight:'20px'}}
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
         {!open &&<div>
        <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/fobe_logo.png" width="110px" style={{marginTop: '-14px'}} />
        </div>
         }
          <div className="containerfg">
          <div style={{ display: 'flex', alignItems: 'center' }}>
                     <Typography variant="body1" sx={{position: 'absolute', right: '50px', color: '#939393', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '24px',}}>
   <AccountCircle style={{color:'#939393'}} /> Welcome <span style={{color: '#19A73F',fontWeight: '500', lineHeight: '24px', textTransform: 'capitalize'}}>{loginUserName}</span>
  </Typography>
  </div>
          </div>
        </Toolbar>
        </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{justifyContent: 'center'}}>
        <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'flex', sm: 'flex', paddingLeft:'0px'} }}
          >
            <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/fobe_logo.png" width="110px" />
          </Typography>
          <IconButton onClick={handleDrawerClose} style={{backgroundColor:'transparent'}}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon style={{marginRight:'-2em',marginTop:'0.5em',}} />}
          </IconButton>
        </DrawerHeader>
       
        <List>
          {FilterMenu?.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton style={isListItemActive(item.link) ? { background: '#19A73F', borderRadius:'3px', margin:'4px 7px 4px 7px', display:'block' } : {margin:'4px 7px 4px 7px', display:'block' }}>
              <Link to={item.link} style={linkStyles}>
              <Tooltip placement="right" title={item.text}>
              <ListItemIcon style={isListItemActive(item.link) ? { color: 'white', fontSize:'20px', minWidth:'42px' } : {fontSize:'20px', minWidth:'42px', color: '#21263C'}}>{item.icon}</ListItemIcon>
              </Tooltip>
              <ListItemText style={isListItemActive(item.link) ? { color: 'white' } : {color: '#21263C'}} primary={item.text} />
     </Link>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div style={{ marginTop: 'auto', display: 'flex', alignItems: 'center',}} onClick={removeStorages}>
        <ListItemButton style={{margin:'4px 7px 4px 7px', display:'block'  }}>
    <Link to="/" style={linkStyles}>
    <Tooltip placement="right" title="Logout">
      <ListItemIcon style={{fontSize: '18px', minWidth: '42px',color: '#21263C' }}>
      <LogoutOutlined />
      </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Log Out" style={{ color: '#21263C' }} />
    </Link>
    </ListItemButton>
  </div>
      </Drawer>
      <Box component="main" sx={{
        flexGrow: 1, transition: 'margin-left 0.2s', // Add transition for a smoother effect
        overflowX: 'hidden', // Hide horizontal scrollbar
      }}>
        <DrawerHeader />
        <>{element}</>
                       </Box>
            
    </Box>
  );
}

export default Header;
