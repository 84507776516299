import React from "react";
import "./split.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { fobeAxios } from "../middleware/interceptor";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ResponsiveAppBar from "../Header/header";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { faI } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "antd";
import Bbutton from "@mui/material/Button";
const SplitPdf = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [recordList, setRecordList] = useState();
  const [images, setimageset] = useState([]);
  const [totalImageCount, setTotalImageCount] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  const navigatetoInvoice = () => {
    navigate("/invoice");
  };

  const checkForDuplicates = (arr) => {
    // Create an array to store unique range1 and pagecount values
    const uniquerange1Values = [];
    const uniquepagecountValues = [];

    // Loop through each object in the array
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];

      // Check for duplicate range1 values
      if (
        uniquerange1Values.includes(obj.range1) ||
        uniquepagecountValues.includes(obj.range1)
      ) {
        return false;
      }

      // Check for duplicate pagecount values
      if (uniquepagecountValues.includes(obj.pagecount)) {
        return false;
      }
      if (uniquerange1Values.includes(obj.pagecount)) {
        if (uniquerange1Values[-1] != obj.pagecount) {
          return false;
        }
      }

      // If the range1 and pagecount values of the current object are not duplicates,
      // add them to the uniquerange1Values and uniquepagecountValues arrays
      uniquerange1Values.push(obj.range1);
      uniquepagecountValues.push(obj.pagecount);
    }

    // If no duplicates are found, return true
    return true;
  };

  const [progress, setProgress] = useState(false);
  const [suceess, setSucess] = useState(false);
  const [fail, setFail] = useState(false);
  const [pageRangeState, setPageRangeState] = useState([]);
  const [pagecount, setPagecountState] = useState([]);

  const [pageType, setPageType] = useState("everyPage");
  // State to keep track of custom page range
  const [pageRange, setPageRange] = useState([{ range1: '0', pagecount: '0' }])
  const [pageGroup, setPageGroup] = useState([{ pages: ""}])

  // Event handler for submit button click
  const handleSubmit = async () => {

    const demo = pageRange.map(range =>{
        return{
          range1: range.range1,
          pagecount: (range.pagecount-range.range1)+1
        }
      })
      console.log(demo)
    let d = {};

    if (pageType == "everyPage") {
      setProgress(true);

      d = {
        rest_id: recordList.Rest_id,
        upload_id: recordList.Upload_id,
        filename: recordList.filename,
        key: "everypage",
        fileinfo: [],
      };
      await fobeAxios
        .post("api/Admin/saveinvoice_details", d)
        .then((y) => {
          if (y.data.savestatus == "file is saved for the process") {
            setProgress(false);
            setSucess(true);
          } else {
            setProgress(false);
            setFail(true);
          }
        })
        .catch(() => {
          setProgress(false);
          setFail(true);
        });
    } else if (pageType == "customPage") {
      let pagenumberArray = [];
      if (images.length > 0) {
        images.map((_, index) => pagenumberArray.push(index + 1));
      }

      const flag = pageRange.every((x) => {
        return (
          pagenumberArray.includes(parseInt(x.range1)) &&
          pagenumberArray.includes(parseInt(x.pagecount))
        );
      })
      const missedpages = (demo, images) => {
        let totalLength = 0;
        demo.forEach(item => {
          const start = parseInt(item.range1);
          const end = start + parseInt(item.pagecount) - 1;
          totalLength += end - start + 1; 
        });

    console.log( totalLength, images.length,"length")
       return totalLength !== images.length;
    };
   const  checkForEmptyRangeAndPageCount=(arr)=> {
      for (const item of arr) {
          if (item.range1 === "" && item.pagecount === 1) {
              return true; 
          }
      }
      return false; 
  }
      const outputArray = Array.from(new Set(pageRangeState.concat(pagecount)));
      console.log(JSON.stringify(outputArray) + "phhh");
      console.log(JSON.stringify(pagenumberArray) + "phhh");
      var count = 0;
      const arraysAreEqual = demo.map(x =>{
            count+= x.pagecount
            if(count == totalImageCount){
              return true;
            }else{
              return false;
            }
      })
      const hasDuplicates = !checkForDuplicates(pageRange);
      let totalLength = 0;
        demo.forEach(item => {
          const start = parseInt(item.range1);
          const end = start + parseInt(item.pagecount) - 1;
          totalLength += end - start + 1; // Calculate the length of the range and add it to the totalLength variable
        });
      if (flag && !hasDuplicates && arraysAreEqual &&  totalLength === images.length) {
        const fInfo = demo
        setProgress(true)
        d = {
          rest_id: recordList.Rest_id,
          upload_id: recordList.Upload_id,
          filename: recordList.filename,
          key: "custom",
          fileinfo: fInfo,
        };
        await fobeAxios
          .post("api/Admin/saveinvoice_details", d)
          .then((y) => {
            if (y.data.savestatus == "file is saved for the process") {
              setProgress(false);
              setSucess(true);
            } else {
              setProgress(false);
              setFail(true);
            }
          })
          .catch(() => {
            setProgress(false);
            setFail(true);
          });
        console.log("bye : " + JSON.stringify(fInfo));
      } 
      else if (checkForEmptyRangeAndPageCount(demo)) {
        setProgress(false)
        toast.warning('Range is empty')
        return;
      }
      else if (!flag) {
        setProgress(false)
        toast.warning('PageNumber Limit Exits')
        return;
      } 
      else if (hasDuplicates) {
        setProgress(false)
        toast.warning('Page Number cannot be repeated')
      } else if (!arraysAreEqual) {
        setProgress(false)
        toast.warning('You must specified All pages')
      }
      if (missedpages(demo, images)) {
        toast.warning("some images were missing");
    }
      console.log(pageRange)
    } else if (pageType == 'group') {

      setProgress(true)
      
     console.log(pageGroup.filter(a=>a.pages== "").length != 0)
      if(pageGroup.filter(a=>a.pages== "").length != 0) {
        console.log(progress)
          toast.warning('Range cannot be empty');
          setProgress(false)
          return;
        } 
 
     if(hasRepeatedNumbers(pageGroup)){
        toast.warning('Page Number cannot be repeated');
        setProgress(false)
        return;
      }
     
      if (pageGroup[0]?.pages?.length !== 0) {
        const allPageNumbers = pageGroup.flatMap(item => item.pages.split(",")).map(Number);
        const expectedPageNumbers = Array.from({ length: images.length }, (_, index) => index + 1).sort(); // Sort expectedPageNumbers
        const sortedAllPageNumbers = allPageNumbers.sort((a, b) => a - b); // Sort allPageNumbers
    
        const isEqual = JSON.stringify(sortedAllPageNumbers) === JSON.stringify(expectedPageNumbers);
        console.log(JSON.stringify(allPageNumbers.sort()) ===JSON.stringify(expectedPageNumbers),isEqual)
        if (allPageNumbers.length > expectedPageNumbers.length) {
            toast.warning('Page Number Limit Exits');
            setProgress(false);
            return; 
        } 
            if (JSON.stringify(sortedAllPageNumbers) != JSON.stringify(expectedPageNumbers)) {
                toast.warning('Some images are missing');
                setProgress(false);
                return; 
            } 
        
    }
    
      d = {
        rest_id: recordList.Rest_id,
        upload_id: recordList.Upload_id,
        filename: recordList.filename,
        key: 'group',
        fileinfo: pageGroup.map(y => ({
          pages: y.pages.split(',').map(u => parseInt(u))
        }))
      }

      await fobeAxios
        .post('api/Admin/saveinvoice_details', d)
        .then(y => {
          if (y.data.savestatus == 'file is saved for the process') {
            setProgress(false)
            setSucess(true)
          } else {
            setProgress(false)
            setFail(true)
          }
        })
        .catch(() => {
          setProgress(false)
          setFail(true)
        }) 
  }
  }
  const hasRepeatedNumbers=(arrayStructure) => {
    const pageNumbers = arrayStructure.flatMap(item => item.pages.split(",").map(Number));
    const pageCounts = pageNumbers.reduce((counts, page) => {
        counts[page] = (counts[page] || 0) + 1;
        return counts;
    }, {});
    
    return Object.values(pageCounts).some(count => count > 1);
}
  const handlePageTypeChange = e => {
    setPageType(e.target.value)
    setPageRange([{ range1: '', pagecount: '' }])
    setPageGroup([{ pages: [] }])
  }

  // Event handler for range input change
  const handleRangeInputChange = (index, field, value) => {
    if (field === "range1") {
      let temp = [...pageRangeState];
      if (value == "" || value == undefined || value == null) {
        temp.slice(index, 1);
      } else {
        temp[index] = value;
      }

      setPageRangeState(temp);
    } else if (field === "pagecount") {
      let temp = [...pagecount];
      if (value == "" || value == undefined || value == null) {
        temp.slice(index, 1);
      } else {
        temp[index] = value;
      }

      setPagecountState(temp);
    }

    const updatedPageRange = [...pageRange];
    updatedPageRange[index][field] = value;
    setPageRange(updatedPageRange);
  };

  const handlegroupRange = (index, value) => {
    let temp = [...pageGroup]
    temp[index].pages = value
    setPageGroup(temp)
  }

  // Event handler for adding a new range input
  const handleAddRange = () => {
    setPageRange([...pageRange, { range1: '', pagecount: '' }])
  }
  const handleAddGroupRange = () => {
    setPageGroup([...pageGroup, { pages: [] }])
  }

  // Event handler for removing a range input
  const handleRemoveRange = (index) => {
    const updatedPageRange = [...pageRange];
    updatedPageRange.splice(index, 1);
    setPageRange(updatedPageRange);
  };
  const handleRemoveGroupRange = index => {
    const updatedPageRange = [...pageGroup]
    updatedPageRange.splice(index, 1)
    setPageGroup(updatedPageRange)
  }
  const [loadingsplit, setloadingsplit] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getImageData = async (record) => {
    scrollToTop();
    setloadingsplit(true);
    const d = {
      rest_id: record.Rest_id,
      upload_id: record.Upload_id,
      filename: record.filename,
    };
    await fobeAxios
      .post("api/Admin/getforoperationpdf", d)
      .then((y) => {
        if (typeof y.data.image_file !== "string") {
          setimageset(y.data.image_file);
          setTotalImageCount(y.data.image_file.length)
          setloadingsplit(false);
        }else{
          toast.warning('Failed')
          setloadingsplit(false)
        }
      })
      .catch(() => {
        setloadingsplit(false);
      });
  };
  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
    }
    const storedUser =
      localStorage.getItem("fobeuser") === "1"
        ? localStorage.getItem("fobeusername")
        : localStorage.getItem("Username");
    if (storedUser) {
      setLoginUserName(storedUser);
    }
  }, [images]);
  useEffect(() => {
    if (location.state) {
      setRecordList(location.state);
      getImageData(location.state);
    }
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuCloseout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.clear();
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const elementDiv = [
    <>
        <Spin tip="Loading Please wait..." spinning={loadingsplit}>
          {" "}
          <div className="container-fluid-well" style={{marginTop:'5em'}}>
            <div
              className="col-xs-12 col-md-12 col-sm-12 col-lg-12 p-0 "
              style={{ background: "#D0CDCD" }}
            >
              <Bbutton
                variant="contained"
                color="success"
                style={{
                  marginTop: "1.5rem",
                  marginBottom: "-1rem",
                  background:"#24D153",
                  marginLeft: "2.5rem",
                  textTransform: "uppercase",
                  color: "#ffffff",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  navigate("/invoice");
                }}
              >
                <i
                  class="fa-solid fa-arrow-left"
                  style={{ fontWeight: "bold" }}
                ></i>
                &nbsp; Back to Invoice
                list
              </Bbutton>
              <div className="row" style={{ padding: "2em",marginLeft:'0px',marginRight:'0px' }}>
                <div className="col-xs-4 col-md-5 col-sm-4 col-lg-3">
                  <div className="card box1">
                    <div>
                      {images.map((image, index) => {
                        return (
                          <div className="spilit">
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={image}
                                onClick={() => handleImageClick(image)}
                                style={{
                                  width: "15em",
                                  marginTop: "2em",
                                  boxShadow: "6px 7px 4px #5f5656",
                                }}
                              />
                            </div>

                            <div
                              style={{ textAlign: "center", marginTop: "1em" }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {index + 1}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-xs-8 col-md-7 col-sm-8 col-lg-6">
                  {selectedImage && (
                    <div className="card box2">
                      <div style={{ textAlign: "center" }} id="img">
                        <img src={selectedImage} className="view-img" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xs-12 col-md-12 col-sm-12 col-lg-3">
                  <div className="card box3">
                    <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12">
                    <div style={{ marginTop: '2em', display: 'flex', gap: '5px' }}>
                      <div
                        style={{ textAlign: 'center' }}
                      >

                        <label
                          class='new-label-class'
                          style={{ color: '#000', fontWeight: '500' }}
                        >
                          <input
                            class='new-input-class'
                            type='radio'
                            style={{ cursor: 'pointer' }}
                            name='pageType'
                            value='everyPage'
                            checked={pageType === 'everyPage'}
                            onChange={handlePageTypeChange}
                            id='ritema'
                          />
                          Every Page
                        </label>

                      </div>
                      <div
                        style={{ textAlign: 'center' }}
                      >

                        <label
                          class='new-label-class'
                          style={{ color: '#000', fontWeight: '500' }}
                        >
                          <input
                            class='new-input-classf'
                            type='radio'
                            style={{ cursor: 'pointer' }}
                            name='pageType'
                            value='customPage'
                            checked={pageType === 'customPage'}
                            onChange={handlePageTypeChange}
                            id='ritema1'
                          />
                          Custom
                        </label>

                      </div>
                      <div
                        style={{ textAlign: 'center' }}
                      >


                        <label
                          class='new-label-class'
                          style={{ color: '#000', fontWeight: '500' }}
                        >
                          <input
                            class='new-input-class'
                            type='radio'
                            style={{ cursor: 'pointer' }}
                            name='group'
                            value='group'
                            checked={pageType === 'group'}
                            onChange={handlePageTypeChange}
                            id='ritema'
                          />
                          Group by
                        </label>


                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Bbutton
                        variant='contained'
                        color='success'
                        // className="pdf-button"
                        style={{
                          width: '105px',
                          height: '35px',
                          background: '#24D153',
                          borderRadius: '3px',
                          color: '#fff',

                          fontWeight: '600',
                          fontSize: '12px',

                          marginTop: '2em'
                        }}
                        onClick={handleSubmit}
                      >
                        Split PDF
                      </Bbutton>
                      <Modal show={progress} centered>
                        <Modal.Body>
                          <div style={{ padding: '20px' }}>
                            <div className='linear-loader'>
                              <div className='indeterminate'></div>
                            </div>
                            <p
                              style={{
                                fontWeight: '500',
                                fontSize: '20px',
                                lineHeight: '39px',
                                color: '#5F5656',
                                textAlign: 'center'
                              }}
                            >
                              Processing......
                            </p>
                          </div>
                        </Modal.Body>
                      </Modal>

                      <Modal show={suceess} centered>
                        <Modal.Body>
                          <div style={{ padding: '20px' }}>
                            <img
                              src='https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/pdf-split.png'
                              className='split-img'
                              style={{ margin: '0 auto', display: 'block' }}
                            />
                            <p
                              style={{
                                fontWeight: '500',
                                fontSize: '20px',
                                lineHeight: '39px',
                                color: '#5F5656',
                                textAlign: 'center'
                              }}
                            >
                              PDF splitted successfully!
                            </p>
                            <button
                              className='btn btn-success'
                              style={{
                                backgroundColor: 'rgb(76, 175, 80)',
                                border: 'none',
                                margin: '0 auto',
                                display: 'block',
                                boxShadow: '1px 1px 4px rgba(0,0,0,0.4)',
                                padding: '6px 16px',
                                fontSize: '13px',
                                lineHeight: '1.846',
                                borderRadius: '3px',
                                textTransform: 'uppercase'
                              }}
                              onClick={navigatetoInvoice}
                            >
                              Done
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <Modal show={fail} centered>
                        <Modal.Body>
                          <div style={{ padding: '20px' }}>
                            <img
                              src='https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/pdf-split.png'
                              className='split-img'
                              style={{ margin: '0 auto', display: 'block' }}
                            />
                            <p
                              style={{
                                fontWeight: '500',
                                fontSize: '20px',
                                lineHeight: '39px',
                                color: '#5F5656',
                                textAlign: 'center'
                              }}
                            >
                              PDF split Failed!
                            </p>
                            <button
                              className='btn btn-success'
                              style={{
                                backgroundColor: 'rgb(76, 175, 80)',
                                border: 'none',
                                margin: '0 auto',
                                display: 'block',
                                boxShadow: '1px 1px 4px rgba(0,0,0,0.4)',
                                padding: '6px 16px',
                                fontSize: '13px',
                                lineHeight: '1.846',
                                borderRadius: '3px',
                                textTransform: 'uppercase'
                              }}
                              onClick={navigatetoInvoice}
                            >
                              Done
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                    {pageType === 'customPage' && (
                      <div>
                        <div className='row' style={{ marginTop: '3em' }}>
                          <div style={{ textAlign: 'center' }}>
                            <div>
                              <button
                                className='range-button'
                                onClick={handleAddRange}
                              >
                                Add Range
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {pageType === 'group' && (
                      <div>
                        <div className='row' style={{ marginTop: '3em' }}>
                          <div style={{ textAlign: 'center' }}>
                            <div>
                              <button
                                className='range-button'
                                onClick={handleAddGroupRange}
                              >
                                Add Range
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='range-box' style={{ padding: '1em' }}>
                      {pageType === 'customPage' && (
                        <>
                          {pageRange.map((range, index) => {
                            return (
                              <div
                                key={index}
                                className='col-xs-12 col-md-12 col-sm-12 col-lg-12'
                                style={{
                                  background: 'rgba(240, 183, 142, 0.12)',
                                  padding: '1em',
                                  marginTop: '5px',
                                  border: '1px solid #CDCDCD'
                                }}
                              >
                                <div>
                                  <div className='row'>
                                    <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                                      <div>
                                        <h3 className='range-text'>
                                          Split {index + 1}
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className='col-xs-6 col-md-6 col-sm-6 col-lg-6'
                                      style={{ marginTop: '-3.7px' }}
                                    >
                                      <button
                                        aria-label='Close'
                                        className='closing'
                                        data-dismiss='modal'
                                        type='button'
                                      >
                                        <span
                                          aria-hidden='true'
                                          style={{
                                            color: '#fff',
                                            position: 'relative',
                                            top: '-4px',
                                            fontWeight: '400'
                                          }}
                                          onClick={() =>
                                            handleRemoveRange(index)
                                          }
                                        >
                                          &times;
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className='row'
                                    style={{ marginTop: '2em' }}
                                  >
                                    <div className='col-xs-5 col-md-5 col-sm-5 col-lg-5'>
                                      <div className='Input_Number'>
                                        <input
                                          type='number'
                                          value={range.range1}
                                          onChange={e =>
                                            handleRangeInputChange(
                                              index,
                                              'range1',
                                              e.target.value
                                            )
                                          }
                                          id='fromrange'
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className='col-xs-2 col-md-2 col-sm-2 col-lg-2'
                                      style={{ textAlign: 'center' }}
                                    >
                                      <span
                                        style={{
                                          color: '#A9A6A6',
                                          marginTop: '12px'
                                        }}
                                      >
                                        <HorizontalRuleIcon
                                          style={{
                                            width: '85%',
                                            marginTop: '5px'
                                          }}
                                        />
                                      </span>
                                    </div>
                                    <div className='col-xs-5 col-md-5 col-sm-5 col-lg-5'>
                                      <div
                                        className='Input_Number'
                                        style={{ float: 'left' }}
                                      >
                                        <input
                                          type='number'
                                          value={range.pagecount}
                                          onChange={e =>
                                            handleRangeInputChange(
                                              index,
                                              'pagecount',
                                              e.target.value
                                            )
                                          }
                                          id='torange'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                      {pageType === 'group' && (
                        <>
                          {pageGroup.map((range, index) => {
                            return (
                              <div
                                key={index}
                                className='col-xs-12 col-md-12 col-sm-12 col-lg-12'
                                style={{
                                  background: 'rgba(240, 183, 142, 0.12)',
                                  padding: '1em',
                                  marginTop: '5px',
                                  border: '1px solid #CDCDCD'
                                }}
                              >
                                <div>
                                  <div className='row'>
                                    <div className='col-xs-6 col-md-6 col-sm-6 col-lg-6'>
                                      <div>
                                        <h3 className='range-text'>
                                          Split {index + 1}
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className='col-xs-6 col-md-6 col-sm-6 col-lg-6'
                                      style={{ marginTop: '-3.7px' }}
                                    >
                                      <button
                                        aria-label='Close'
                                        className='closing'
                                        data-dismiss='modal'
                                        type='button'
                                      >
                                        <span
                                          aria-hidden='true'
                                          style={{
                                            color: '#fff',
                                            position: 'relative',
                                            top: '-4px',
                                            fontWeight: '400'
                                          }}
                                          onClick={() =>
                                            handleRemoveGroupRange(index)
                                          }
                                        >
                                          &times;
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className='row'
                                    style={{ marginTop: '2em' }}
                                  >
                                    <div className='col-xs-5 col-md-5 col-sm-5 col-lg-5'>
                                      <div className='Input_Number'>
                                        <input
                                          type='text'
                                          value={
                                            range.pages.length > 0
                                              ? range.pages
                                              : ''
                                          }
                                          onChange={e => {
                                            handlegroupRange(
                                              index,
                                              e.target.value
                                            )
                                          }}
                                          id='fromrange'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  ]
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div>
        <ResponsiveAppBar
          handleMobileMenuClose={handleMobileMenuClose}
          anchorEl={anchorEl}
          handleProfileMenuOpen={handleProfileMenuOpen}
          handleMenuClose={handleMenuClose}
          loginUserName={loginUserName}
          handleMenuCloseout={handleMenuCloseout}
          handleMobileMenuOpen={handleMobileMenuOpen}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          element={elementDiv}
        />
      </div>
    </>
  );
};
export default SplitPdf;
